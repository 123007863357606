%alpha {
	@include font-size(28px);
	font-weight: 300;

	@include font-size(30px, $small);
	@include font-size(36px, $nexus);
	@include font-size(38px, $medium);
	@include font-size(38px, $ipad);
	@include font-size(40px, $large);
	@include font-size(40px, $xlarge);
	@include font-size(40px, $huge);
	@include font-size(40px, $max);
}

%beta {
	@include font-size(18px);
	font-weight: 300;

	@include font-size(20px, $small);
	@include font-size(22px, $nexus);
	@include font-size(24px, $medium);
	@include font-size(26px, $ipad);
	@include font-size(30px, $large);
	@include font-size(30px, $xlarge);
	@include font-size(30px, $huge);
	@include font-size(30px, $max);
}

%gamma {
	@include font-size(16px);
	font-weight: 300;

	@include font-size(16px, $small);
	@include font-size(18px, $nexus);
	@include font-size(19px, $medium);
	@include font-size(20px, $ipad);
	@include font-size(20px, $large);
	@include font-size(20px, $xlarge);
	@include font-size(22px, $huge);
	@include font-size(22px, $max);
}

%delta {
	@include font-size(16px);

	@include font-size(16px, $small);
	@include font-size(16px, $nexus);
	@include font-size(16px, $medium);
	@include font-size(18px, $ipad);
	@include font-size(18px, $large);
	@include font-size(18px, $xlarge);
	@include font-size(18px, $huge);
}

%base {
	@include font-size(14px);

	@include font-size(14px, $small);
	@include font-size(14px, $nexus);
	@include font-size(14px, $medium);
	@include font-size(14px, $ipad);
	@include font-size(14px, $large);
	@include font-size(14px, $xlarge);
	@include font-size(14px, $huge);
}

%lower {
	text-transform: lowercase;
}