@font-face {
  font-family: 'Maison Neue';
  src: url("/fonts/MaisonNeue-Light.woff2") format("woff2"), url("/fonts/MaisonNeue-Light.woff") format("woff"), url("/fonts/MaisonNeue-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Maison Neue';
  src: url("/fonts/MaisonNeue-LightItalic.woff2") format("woff2"), url("/fonts/MaisonNeue-LightItalic.woff") format("woff"), url("/fonts/MaisonNeue-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'Maison Neue';
  src: url("/fonts/MaisonNeue-Book.woff2") format("woff2"), url("/fonts/MaisonNeue-Book.woff") format("woff"), url("/fonts/MaisonNeue-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Maison Neue';
  src: url("/fonts/MaisonNeue-BookItalic.woff2") format("woff2"), url("/fonts/MaisonNeue-BookItalic.woff") format("woff"), url("/fonts/MaisonNeue-BookItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Maison Neue';
  src: url("/fonts/MaisonNeue-Demi.woff2") format("woff2"), url("/fonts/MaisonNeue-Demi.woff") format("woff"), url("/fonts/MaisonNeue-Demi.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Maison Neue';
  src: url("/fonts/MaisonNeue-DemiItalic.woff2") format("woff2"), url("/fonts/MaisonNeue-DemiItalic.woff") format("woff"), url("/fonts/MaisonNeue-DemiItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic; }

/**
 * Comment this out in production!
 */
/* --------------------------------------------------
    #FLUID TYPE
    Responsive Type
    More Infos: http://madebymike.com.au/writing/fluid-type-calc-examples/

    Single property
    html {
      @include fluid-type(font-size, 320px, 1366px, 14px, 18px);
    }

    Multiple properties with same values
    h1 {
      @include fluid-type(padding-bottom padding-top, 20em, 70em, 2em, 4em);
    }
   -------------------------------------------------- */
html {
  font-size: 10px; }
  @media screen and (min-width: 480px) {
    html {
      font-size: 12px; } }
  @media screen and (min-width: 600px) {
    html {
      font-size: 12px; } }
  @media screen and (min-width: 768px) {
    html {
      font-size: 14px; } }
  @media screen and (min-width: 1024px) {
    html {
      font-size: 16px; } }
  @media screen and (min-width: 1280px) {
    html {
      font-size: 18px; } }
  @media screen and (min-width: 1440px) {
    html {
      font-size: 20px; } }
  @media screen and (min-width: 1681px) {
    html {
      font-size: 20px; } }
  @media screen and (min-width: 1920px) {
    html {
      font-size: 20px; } }

body {
  color: #2e3241;
  font-family: "Maison Neue", sans-serif;
  font-weight: 400;
  font-feature-settings: "kern", "liga", "pnum";
  line-height: 1.5;
  margin: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Maison Neue", sans-serif, sans-serif;
  line-height: 1.2;
  margin: 0 0 0.75em; }

p {
  margin: 0 0 0.75em; }

a {
  color: #2e3241;
  text-decoration: none;
  transition: color 300ms ease-in-out; }
  a:active, a:focus, a:hover {
    color: #2e3241; }

hr {
  border-bottom: 1px solid #000;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: 1.5em 0; }

img,
picture {
  margin: 0;
  max-width: 100%; }

ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0; }

dl {
  margin-bottom: 0.75em; }
  dl dt {
    font-weight: bold;
    margin-top: 0.75em; }
  dl dd {
    margin: 0; }

table {
  border-collapse: collapse;
  font-feature-settings: "kern", "liga", "tnum";
  margin: 0;
  width: 100%; }

th {
  border-bottom: 1px solid shade(#000, 25%);
  font-weight: 600;
  padding: 0.75em 0;
  text-align: left; }

td {
  border-bottom: 1px solid #000;
  padding: 0.75em 0; }

tr,
td,
th {
  vertical-align: middle; }

.embed-container,
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  margin: 0 0 0.75em;
  font-size: 16px; }
  .embed-container iframe, .embed-container object, .embed-container embed,
  .video-container iframe,
  .video-container object,
  .video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

/**
 * Visually hide an element, but leave it available for screen readers
 * @link https://github.com/h5bp/html5-boilerplate/blob/master/dist/css/main.css
 * @link http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.screen-reader {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

/**
 * Extends the .screen-reader class to allow the element to be focusable when navigated to via the keyboard
 * @link https://github.com/h5bp/html5-boilerplate/blob/master/dist/css/main.css
 * @link https://www.drupal.org/node/897638
 */
.screen-reader-focusable:active,
.screen-reader-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

.main .title__heading, .main .title__timestamp {
  font-size: 28px;
  font-size: 2.8rem;
  font-weight: 300; }
  @media screen and (min-width: 480px) {
    .main .title__heading, .main .title__timestamp {
      font-size: 30px;
      font-size: 2.5rem; } }
  @media screen and (min-width: 600px) {
    .main .title__heading, .main .title__timestamp {
      font-size: 36px;
      font-size: 3rem; } }
  @media screen and (min-width: 768px) {
    .main .title__heading, .main .title__timestamp {
      font-size: 38px;
      font-size: 2.71429rem; } }
  @media screen and (min-width: 1024px) {
    .main .title__heading, .main .title__timestamp {
      font-size: 38px;
      font-size: 2.375rem; } }
  @media screen and (min-width: 1280px) {
    .main .title__heading, .main .title__timestamp {
      font-size: 40px;
      font-size: 2.22222rem; } }
  @media screen and (min-width: 1440px) {
    .main .title__heading, .main .title__timestamp {
      font-size: 40px;
      font-size: 2rem; } }
  @media screen and (min-width: 1681px) {
    .main .title__heading, .main .title__timestamp {
      font-size: 40px;
      font-size: 2rem; } }
  @media screen and (min-width: 1920px) {
    .main .title__heading, .main .title__timestamp {
      font-size: 40px;
      font-size: 2rem; } }

.dropdown--topnav .icon, .heading, .summary:before, .panel__filters:before, .panel__filters:after {
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 300; }
  @media screen and (min-width: 480px) {
    .dropdown--topnav .icon, .heading, .summary:before, .panel__filters:before, .panel__filters:after {
      font-size: 20px;
      font-size: 1.66667rem; } }
  @media screen and (min-width: 600px) {
    .dropdown--topnav .icon, .heading, .summary:before, .panel__filters:before, .panel__filters:after {
      font-size: 22px;
      font-size: 1.83333rem; } }
  @media screen and (min-width: 768px) {
    .dropdown--topnav .icon, .heading, .summary:before, .panel__filters:before, .panel__filters:after {
      font-size: 24px;
      font-size: 1.71429rem; } }
  @media screen and (min-width: 1024px) {
    .dropdown--topnav .icon, .heading, .summary:before, .panel__filters:before, .panel__filters:after {
      font-size: 26px;
      font-size: 1.625rem; } }
  @media screen and (min-width: 1280px) {
    .dropdown--topnav .icon, .heading, .summary:before, .panel__filters:before, .panel__filters:after {
      font-size: 30px;
      font-size: 1.66667rem; } }
  @media screen and (min-width: 1440px) {
    .dropdown--topnav .icon, .heading, .summary:before, .panel__filters:before, .panel__filters:after {
      font-size: 30px;
      font-size: 1.5rem; } }
  @media screen and (min-width: 1681px) {
    .dropdown--topnav .icon, .heading, .summary:before, .panel__filters:before, .panel__filters:after {
      font-size: 30px;
      font-size: 1.5rem; } }
  @media screen and (min-width: 1920px) {
    .dropdown--topnav .icon, .heading, .summary:before, .panel__filters:before, .panel__filters:after {
      font-size: 30px;
      font-size: 1.5rem; } }

.pillar--large, .compare__status, .side__nav, .panel__rich h3, .accordion .accordion__content h3 {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 300; }
  @media screen and (min-width: 480px) {
    .pillar--large, .compare__status, .side__nav, .panel__rich h3, .accordion .accordion__content h3 {
      font-size: 16px;
      font-size: 1.33333rem; } }
  @media screen and (min-width: 600px) {
    .pillar--large, .compare__status, .side__nav, .panel__rich h3, .accordion .accordion__content h3 {
      font-size: 18px;
      font-size: 1.5rem; } }
  @media screen and (min-width: 768px) {
    .pillar--large, .compare__status, .side__nav, .panel__rich h3, .accordion .accordion__content h3 {
      font-size: 19px;
      font-size: 1.35714rem; } }
  @media screen and (min-width: 1024px) {
    .pillar--large, .compare__status, .side__nav, .panel__rich h3, .accordion .accordion__content h3 {
      font-size: 20px;
      font-size: 1.25rem; } }
  @media screen and (min-width: 1280px) {
    .pillar--large, .compare__status, .side__nav, .panel__rich h3, .accordion .accordion__content h3 {
      font-size: 20px;
      font-size: 1.11111rem; } }
  @media screen and (min-width: 1440px) {
    .pillar--large, .compare__status, .side__nav, .panel__rich h3, .accordion .accordion__content h3 {
      font-size: 20px;
      font-size: 1rem; } }
  @media screen and (min-width: 1681px) {
    .pillar--large, .compare__status, .side__nav, .panel__rich h3, .accordion .accordion__content h3 {
      font-size: 22px;
      font-size: 1.1rem; } }
  @media screen and (min-width: 1920px) {
    .pillar--large, .compare__status, .side__nav, .panel__rich h3, .accordion .accordion__content h3 {
      font-size: 22px;
      font-size: 1.1rem; } }

.dropdown__label, .dropdown__btn, .legend, .pillar--data-point, .pillar__stat, .guide__gradient-point, .guide__row, .glossary__row, .metrics, .metrics__heading, .summary-list, .summary-list__title, .rank-table, .data-point, .outlook__content, .summary__recommendation, .summary__description, .summary__links, .side__heading, .panel__rich p, .accordion .accordion__content p, .footer__disclaimer h2, .footer__links ul li {
  font-size: 16px;
  font-size: 1.6rem; }
  @media screen and (min-width: 480px) {
    .dropdown__label, .dropdown__btn, .legend, .pillar--data-point, .pillar__stat, .guide__gradient-point, .guide__row, .glossary__row, .metrics, .metrics__heading, .summary-list, .summary-list__title, .rank-table, .data-point, .outlook__content, .summary__recommendation, .summary__description, .summary__links, .side__heading, .panel__rich p, .accordion .accordion__content p, .footer__disclaimer h2, .footer__links ul li {
      font-size: 16px;
      font-size: 1.33333rem; } }
  @media screen and (min-width: 600px) {
    .dropdown__label, .dropdown__btn, .legend, .pillar--data-point, .pillar__stat, .guide__gradient-point, .guide__row, .glossary__row, .metrics, .metrics__heading, .summary-list, .summary-list__title, .rank-table, .data-point, .outlook__content, .summary__recommendation, .summary__description, .summary__links, .side__heading, .panel__rich p, .accordion .accordion__content p, .footer__disclaimer h2, .footer__links ul li {
      font-size: 16px;
      font-size: 1.33333rem; } }
  @media screen and (min-width: 768px) {
    .dropdown__label, .dropdown__btn, .legend, .pillar--data-point, .pillar__stat, .guide__gradient-point, .guide__row, .glossary__row, .metrics, .metrics__heading, .summary-list, .summary-list__title, .rank-table, .data-point, .outlook__content, .summary__recommendation, .summary__description, .summary__links, .side__heading, .panel__rich p, .accordion .accordion__content p, .footer__disclaimer h2, .footer__links ul li {
      font-size: 16px;
      font-size: 1.14286rem; } }
  @media screen and (min-width: 1024px) {
    .dropdown__label, .dropdown__btn, .legend, .pillar--data-point, .pillar__stat, .guide__gradient-point, .guide__row, .glossary__row, .metrics, .metrics__heading, .summary-list, .summary-list__title, .rank-table, .data-point, .outlook__content, .summary__recommendation, .summary__description, .summary__links, .side__heading, .panel__rich p, .accordion .accordion__content p, .footer__disclaimer h2, .footer__links ul li {
      font-size: 18px;
      font-size: 1.125rem; } }
  @media screen and (min-width: 1280px) {
    .dropdown__label, .dropdown__btn, .legend, .pillar--data-point, .pillar__stat, .guide__gradient-point, .guide__row, .glossary__row, .metrics, .metrics__heading, .summary-list, .summary-list__title, .rank-table, .data-point, .outlook__content, .summary__recommendation, .summary__description, .summary__links, .side__heading, .panel__rich p, .accordion .accordion__content p, .footer__disclaimer h2, .footer__links ul li {
      font-size: 18px;
      font-size: 1rem; } }
  @media screen and (min-width: 1440px) {
    .dropdown__label, .dropdown__btn, .legend, .pillar--data-point, .pillar__stat, .guide__gradient-point, .guide__row, .glossary__row, .metrics, .metrics__heading, .summary-list, .summary-list__title, .rank-table, .data-point, .outlook__content, .summary__recommendation, .summary__description, .summary__links, .side__heading, .panel__rich p, .accordion .accordion__content p, .footer__disclaimer h2, .footer__links ul li {
      font-size: 18px;
      font-size: 0.9rem; } }
  @media screen and (min-width: 1681px) {
    .dropdown__label, .dropdown__btn, .legend, .pillar--data-point, .pillar__stat, .guide__gradient-point, .guide__row, .glossary__row, .metrics, .metrics__heading, .summary-list, .summary-list__title, .rank-table, .data-point, .outlook__content, .summary__recommendation, .summary__description, .summary__links, .side__heading, .panel__rich p, .accordion .accordion__content p, .footer__disclaimer h2, .footer__links ul li {
      font-size: 18px;
      font-size: 0.9rem; } }

.btn, .btn--outline, .dropdown__select, .dropdown__input, .dropdown .v-select input[type=search],
.dropdown .v-select input[type=search]:focus, .dropdown .v-select .selected-tag, .dropdown .v-select .dropdown-menu, .legend__definition, .scoreguide, .scoreguide__heading, .summary-list--titled, .radar__legend, .scatter__legend, .heading--button .btn, .side__slide, .footer__disclaimer p, .internal__accordion [data-control], .pagination, .pagination a,
.pagination .active span, .mobile-pagination h3 {
  font-size: 14px;
  font-size: 1.4rem; }
  @media screen and (min-width: 480px) {
    .btn, .btn--outline, .dropdown__select, .dropdown__input, .dropdown .v-select input[type=search],
    .dropdown .v-select input[type=search]:focus, .dropdown .v-select .selected-tag, .dropdown .v-select .dropdown-menu, .legend__definition, .scoreguide, .scoreguide__heading, .summary-list--titled, .radar__legend, .scatter__legend, .heading--button .btn, .side__slide, .footer__disclaimer p, .internal__accordion [data-control], .pagination, .pagination a,
    .pagination .active span, .mobile-pagination h3 {
      font-size: 14px;
      font-size: 1.16667rem; } }
  @media screen and (min-width: 600px) {
    .btn, .btn--outline, .dropdown__select, .dropdown__input, .dropdown .v-select input[type=search],
    .dropdown .v-select input[type=search]:focus, .dropdown .v-select .selected-tag, .dropdown .v-select .dropdown-menu, .legend__definition, .scoreguide, .scoreguide__heading, .summary-list--titled, .radar__legend, .scatter__legend, .heading--button .btn, .side__slide, .footer__disclaimer p, .internal__accordion [data-control], .pagination, .pagination a,
    .pagination .active span, .mobile-pagination h3 {
      font-size: 14px;
      font-size: 1.16667rem; } }
  @media screen and (min-width: 768px) {
    .btn, .btn--outline, .dropdown__select, .dropdown__input, .dropdown .v-select input[type=search],
    .dropdown .v-select input[type=search]:focus, .dropdown .v-select .selected-tag, .dropdown .v-select .dropdown-menu, .legend__definition, .scoreguide, .scoreguide__heading, .summary-list--titled, .radar__legend, .scatter__legend, .heading--button .btn, .side__slide, .footer__disclaimer p, .internal__accordion [data-control], .pagination, .pagination a,
    .pagination .active span, .mobile-pagination h3 {
      font-size: 14px;
      font-size: 1rem; } }
  @media screen and (min-width: 1024px) {
    .btn, .btn--outline, .dropdown__select, .dropdown__input, .dropdown .v-select input[type=search],
    .dropdown .v-select input[type=search]:focus, .dropdown .v-select .selected-tag, .dropdown .v-select .dropdown-menu, .legend__definition, .scoreguide, .scoreguide__heading, .summary-list--titled, .radar__legend, .scatter__legend, .heading--button .btn, .side__slide, .footer__disclaimer p, .internal__accordion [data-control], .pagination, .pagination a,
    .pagination .active span, .mobile-pagination h3 {
      font-size: 14px;
      font-size: 0.875rem; } }
  @media screen and (min-width: 1280px) {
    .btn, .btn--outline, .dropdown__select, .dropdown__input, .dropdown .v-select input[type=search],
    .dropdown .v-select input[type=search]:focus, .dropdown .v-select .selected-tag, .dropdown .v-select .dropdown-menu, .legend__definition, .scoreguide, .scoreguide__heading, .summary-list--titled, .radar__legend, .scatter__legend, .heading--button .btn, .side__slide, .footer__disclaimer p, .internal__accordion [data-control], .pagination, .pagination a,
    .pagination .active span, .mobile-pagination h3 {
      font-size: 14px;
      font-size: 0.77778rem; } }
  @media screen and (min-width: 1440px) {
    .btn, .btn--outline, .dropdown__select, .dropdown__input, .dropdown .v-select input[type=search],
    .dropdown .v-select input[type=search]:focus, .dropdown .v-select .selected-tag, .dropdown .v-select .dropdown-menu, .legend__definition, .scoreguide, .scoreguide__heading, .summary-list--titled, .radar__legend, .scatter__legend, .heading--button .btn, .side__slide, .footer__disclaimer p, .internal__accordion [data-control], .pagination, .pagination a,
    .pagination .active span, .mobile-pagination h3 {
      font-size: 14px;
      font-size: 0.7rem; } }
  @media screen and (min-width: 1681px) {
    .btn, .btn--outline, .dropdown__select, .dropdown__input, .dropdown .v-select input[type=search],
    .dropdown .v-select input[type=search]:focus, .dropdown .v-select .selected-tag, .dropdown .v-select .dropdown-menu, .legend__definition, .scoreguide, .scoreguide__heading, .summary-list--titled, .radar__legend, .scatter__legend, .heading--button .btn, .side__slide, .footer__disclaimer p, .internal__accordion [data-control], .pagination, .pagination a,
    .pagination .active span, .mobile-pagination h3 {
      font-size: 14px;
      font-size: 0.7rem; } }

/*----------------------------------------------------------------------*  # Flexbox Mixins
\*----------------------------------------------------------------------*/
.wrapper {
  box-sizing: border-box;
  max-width: 1920px;
  margin: 0 auto; }

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: 0;
  padding-left: 0; }

.row {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -moz-box-flex: 0;
  -moz-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -0.9rem;
  margin-left: -0.9rem; }

.row.reverse {
  -webkit-box-direction: reverse;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row-reverse;
  -moz-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse; }

.col.reverse {
  -webkit-box-direction: reverse;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column-reverse;
  -moz-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse; }

.col-xs {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  -webkit-flex-basis: auto;
  -moz-flex-basis: auto;
  -ms-flex-preferred-size: auto;
  flex-basis: auto; }

.col-xs-1 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  -webkit-flex-basis: 8.33333%;
  -moz-flex-basis: 8.33333%;
  -ms-flex-preferred-size: 8.33333%;
  flex-basis: 8.33333%;
  max-width: 8.33333%; }

.col-xs-2 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  -webkit-flex-basis: 16.66667%;
  -moz-flex-basis: 16.66667%;
  -ms-flex-preferred-size: 16.66667%;
  flex-basis: 16.66667%;
  max-width: 16.66667%; }

.col-xs-3 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  -webkit-flex-basis: 25%;
  -moz-flex-basis: 25%;
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  max-width: 25%; }

.col-xs-4 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  -webkit-flex-basis: 33.33333%;
  -moz-flex-basis: 33.33333%;
  -ms-flex-preferred-size: 33.33333%;
  flex-basis: 33.33333%;
  max-width: 33.33333%; }

.col-xs-5 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  -webkit-flex-basis: 41.66667%;
  -moz-flex-basis: 41.66667%;
  -ms-flex-preferred-size: 41.66667%;
  flex-basis: 41.66667%;
  max-width: 41.66667%; }

.col-xs-6 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  -webkit-flex-basis: 50%;
  -moz-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%; }

.col-xs-7 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  -webkit-flex-basis: 58.33333%;
  -moz-flex-basis: 58.33333%;
  -ms-flex-preferred-size: 58.33333%;
  flex-basis: 58.33333%;
  max-width: 58.33333%; }

.col-xs-8 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  -webkit-flex-basis: 66.66667%;
  -moz-flex-basis: 66.66667%;
  -ms-flex-preferred-size: 66.66667%;
  flex-basis: 66.66667%;
  max-width: 66.66667%; }

.col-xs-9 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  -webkit-flex-basis: 75%;
  -moz-flex-basis: 75%;
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
  max-width: 75%; }

.col-xs-10 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  -webkit-flex-basis: 83.33333%;
  -moz-flex-basis: 83.33333%;
  -ms-flex-preferred-size: 83.33333%;
  flex-basis: 83.33333%;
  max-width: 83.33333%; }

.col-xs-11 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  -webkit-flex-basis: 91.66667%;
  -moz-flex-basis: 91.66667%;
  -ms-flex-preferred-size: 91.66667%;
  flex-basis: 91.66667%;
  max-width: 91.66667%; }

.col-xs-12 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  -webkit-flex-basis: 100%;
  -moz-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%; }

.col-xs-push-0 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  margin-left: 0; }

.col-xs-pull-0 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  margin-right: 0; }

.col-xs-push-1 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  margin-left: 8.33333%; }

.col-xs-pull-1 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  margin-right: 8.33333%; }

.col-xs-push-2 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  margin-left: 16.66667%; }

.col-xs-pull-2 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  margin-right: 16.66667%; }

.col-xs-push-3 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  margin-left: 25%; }

.col-xs-pull-3 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  margin-right: 25%; }

.col-xs-push-4 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  margin-left: 33.33333%; }

.col-xs-pull-4 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  margin-right: 33.33333%; }

.col-xs-push-5 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  margin-left: 41.66667%; }

.col-xs-pull-5 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  margin-right: 41.66667%; }

.col-xs-push-6 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  margin-left: 50%; }

.col-xs-pull-6 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  margin-right: 50%; }

.col-xs-push-7 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  margin-left: 58.33333%; }

.col-xs-pull-7 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  margin-right: 58.33333%; }

.col-xs-push-8 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  margin-left: 66.66667%; }

.col-xs-pull-8 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  margin-right: 66.66667%; }

.col-xs-push-9 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  margin-left: 75%; }

.col-xs-pull-9 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  margin-right: 75%; }

.col-xs-push-10 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  margin-left: 83.33333%; }

.col-xs-pull-10 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  margin-right: 83.33333%; }

.col-xs-push-11 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  margin-left: 91.66667%; }

.col-xs-pull-11 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  margin-right: 91.66667%; }

.col-xs-push-12 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  margin-left: 100%; }

.col-xs-pull-12 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  margin-right: 100%; }

.col-xs {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-basis: 0;
  -moz-flex-basis: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  max-width: 100%; }

.start-xs {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  text-align: left; }

.center-xs {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  text-align: center; }

.end-xs {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end;
  text-align: right; }

.top-xs {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start; }

.middle-xs {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.bottom-xs {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end; }

.around-xs {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around; }

.between-xs {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }

.first-xs {
  order: -1; }

.last-xs {
  order: 1; }

@media only screen and (min-width: 480px) {
  .container {
    width: 480px; }
  .col-sm {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: auto;
    -moz-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .col-sm-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 8.33333%;
    -moz-flex-basis: 8.33333%;
    -ms-flex-preferred-size: 8.33333%;
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 16.66667%;
    -moz-flex-basis: 16.66667%;
    -ms-flex-preferred-size: 16.66667%;
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 25%;
    -moz-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-sm-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 33.33333%;
    -moz-flex-basis: 33.33333%;
    -ms-flex-preferred-size: 33.33333%;
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 41.66667%;
    -moz-flex-basis: 41.66667%;
    -ms-flex-preferred-size: 41.66667%;
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 50%;
    -moz-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-sm-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 58.33333%;
    -moz-flex-basis: 58.33333%;
    -ms-flex-preferred-size: 58.33333%;
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 66.66667%;
    -moz-flex-basis: 66.66667%;
    -ms-flex-preferred-size: 66.66667%;
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 75%;
    -moz-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-sm-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 83.33333%;
    -moz-flex-basis: 83.33333%;
    -ms-flex-preferred-size: 83.33333%;
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 91.66667%;
    -moz-flex-basis: 91.66667%;
    -ms-flex-preferred-size: 91.66667%;
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-sm-push-0 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 0; }
  .col-sm-pull-0 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 0; }
  .col-sm-push-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 8.33333%; }
  .col-sm-pull-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 8.33333%; }
  .col-sm-push-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 16.66667%; }
  .col-sm-pull-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 16.66667%; }
  .col-sm-push-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 25%; }
  .col-sm-pull-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 25%; }
  .col-sm-push-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 33.33333%; }
  .col-sm-pull-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 33.33333%; }
  .col-sm-push-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 41.66667%; }
  .col-sm-pull-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 41.66667%; }
  .col-sm-push-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 50%; }
  .col-sm-pull-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 50%; }
  .col-sm-push-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 58.33333%; }
  .col-sm-pull-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 58.33333%; }
  .col-sm-push-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 66.66667%; }
  .col-sm-pull-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 66.66667%; }
  .col-sm-push-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 75%; }
  .col-sm-pull-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 75%; }
  .col-sm-push-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 83.33333%; }
  .col-sm-pull-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 83.33333%; }
  .col-sm-push-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 91.66667%; }
  .col-sm-pull-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 91.66667%; }
  .col-sm-push-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 100%; }
  .col-sm-pull-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 100%; }
  .col-sm {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: 0;
    -moz-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%; }
  .start-sm {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: left; }
  .center-sm {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    text-align: center; }
  .end-sm {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: right; }
  .top-sm {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    align-items: flex-start; }
  .middle-sm {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center; }
  .bottom-sm {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    align-items: flex-end; }
  .around-sm {
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    justify-content: space-around; }
  .between-sm {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
  .first-sm {
    order: -1; }
  .last-sm {
    order: 1; } }

@media only screen and (min-width: 600px) {
  .container {
    width: 600px; }
  .col-nx {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: auto;
    -moz-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .col-nx-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 8.33333%;
    -moz-flex-basis: 8.33333%;
    -ms-flex-preferred-size: 8.33333%;
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-nx-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 16.66667%;
    -moz-flex-basis: 16.66667%;
    -ms-flex-preferred-size: 16.66667%;
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-nx-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 25%;
    -moz-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-nx-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 33.33333%;
    -moz-flex-basis: 33.33333%;
    -ms-flex-preferred-size: 33.33333%;
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-nx-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 41.66667%;
    -moz-flex-basis: 41.66667%;
    -ms-flex-preferred-size: 41.66667%;
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-nx-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 50%;
    -moz-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-nx-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 58.33333%;
    -moz-flex-basis: 58.33333%;
    -ms-flex-preferred-size: 58.33333%;
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-nx-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 66.66667%;
    -moz-flex-basis: 66.66667%;
    -ms-flex-preferred-size: 66.66667%;
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-nx-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 75%;
    -moz-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-nx-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 83.33333%;
    -moz-flex-basis: 83.33333%;
    -ms-flex-preferred-size: 83.33333%;
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-nx-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 91.66667%;
    -moz-flex-basis: 91.66667%;
    -ms-flex-preferred-size: 91.66667%;
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-nx-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-nx-push-0 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 0; }
  .col-nx-pull-0 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 0; }
  .col-nx-push-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 8.33333%; }
  .col-nx-pull-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 8.33333%; }
  .col-nx-push-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 16.66667%; }
  .col-nx-pull-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 16.66667%; }
  .col-nx-push-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 25%; }
  .col-nx-pull-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 25%; }
  .col-nx-push-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 33.33333%; }
  .col-nx-pull-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 33.33333%; }
  .col-nx-push-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 41.66667%; }
  .col-nx-pull-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 41.66667%; }
  .col-nx-push-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 50%; }
  .col-nx-pull-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 50%; }
  .col-nx-push-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 58.33333%; }
  .col-nx-pull-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 58.33333%; }
  .col-nx-push-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 66.66667%; }
  .col-nx-pull-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 66.66667%; }
  .col-nx-push-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 75%; }
  .col-nx-pull-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 75%; }
  .col-nx-push-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 83.33333%; }
  .col-nx-pull-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 83.33333%; }
  .col-nx-push-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 91.66667%; }
  .col-nx-pull-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 91.66667%; }
  .col-nx-push-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 100%; }
  .col-nx-pull-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 100%; }
  .col-nx {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: 0;
    -moz-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%; }
  .start-nx {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: left; }
  .center-nx {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    text-align: center; }
  .end-nx {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: right; }
  .top-nx {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    align-items: flex-start; }
  .middle-nx {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center; }
  .bottom-nx {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    align-items: flex-end; }
  .around-nx {
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    justify-content: space-around; }
  .between-nx {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
  .first-nx {
    order: -1; }
  .last-nx {
    order: 1; } }

@media only screen and (min-width: 768px) {
  .container {
    width: 768px; }
  .col-md {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: auto;
    -moz-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .col-md-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 8.33333%;
    -moz-flex-basis: 8.33333%;
    -ms-flex-preferred-size: 8.33333%;
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 16.66667%;
    -moz-flex-basis: 16.66667%;
    -ms-flex-preferred-size: 16.66667%;
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 25%;
    -moz-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-md-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 33.33333%;
    -moz-flex-basis: 33.33333%;
    -ms-flex-preferred-size: 33.33333%;
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 41.66667%;
    -moz-flex-basis: 41.66667%;
    -ms-flex-preferred-size: 41.66667%;
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 50%;
    -moz-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-md-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 58.33333%;
    -moz-flex-basis: 58.33333%;
    -ms-flex-preferred-size: 58.33333%;
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 66.66667%;
    -moz-flex-basis: 66.66667%;
    -ms-flex-preferred-size: 66.66667%;
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 75%;
    -moz-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-md-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 83.33333%;
    -moz-flex-basis: 83.33333%;
    -ms-flex-preferred-size: 83.33333%;
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 91.66667%;
    -moz-flex-basis: 91.66667%;
    -ms-flex-preferred-size: 91.66667%;
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-md-push-0 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 0; }
  .col-md-pull-0 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 0; }
  .col-md-push-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 8.33333%; }
  .col-md-pull-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 8.33333%; }
  .col-md-push-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 16.66667%; }
  .col-md-pull-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 16.66667%; }
  .col-md-push-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 25%; }
  .col-md-pull-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 25%; }
  .col-md-push-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 33.33333%; }
  .col-md-pull-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 33.33333%; }
  .col-md-push-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 41.66667%; }
  .col-md-pull-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 41.66667%; }
  .col-md-push-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 50%; }
  .col-md-pull-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 50%; }
  .col-md-push-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 58.33333%; }
  .col-md-pull-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 58.33333%; }
  .col-md-push-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 66.66667%; }
  .col-md-pull-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 66.66667%; }
  .col-md-push-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 75%; }
  .col-md-pull-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 75%; }
  .col-md-push-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 83.33333%; }
  .col-md-pull-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 83.33333%; }
  .col-md-push-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 91.66667%; }
  .col-md-pull-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 91.66667%; }
  .col-md-push-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 100%; }
  .col-md-pull-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 100%; }
  .col-md {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: 0;
    -moz-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%; }
  .start-md {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: left; }
  .center-md {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    text-align: center; }
  .end-md {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: right; }
  .top-md {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    align-items: flex-start; }
  .middle-md {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center; }
  .bottom-md {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    align-items: flex-end; }
  .around-md {
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    justify-content: space-around; }
  .between-md {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
  .first-md {
    order: -1; }
  .last-md {
    order: 1; } }

@media only screen and (min-width: 1024px) {
  .container {
    width: 1024px; }
  .col-ip {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: auto;
    -moz-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .col-ip-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 8.33333%;
    -moz-flex-basis: 8.33333%;
    -ms-flex-preferred-size: 8.33333%;
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-ip-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 16.66667%;
    -moz-flex-basis: 16.66667%;
    -ms-flex-preferred-size: 16.66667%;
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-ip-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 25%;
    -moz-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-ip-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 33.33333%;
    -moz-flex-basis: 33.33333%;
    -ms-flex-preferred-size: 33.33333%;
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-ip-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 41.66667%;
    -moz-flex-basis: 41.66667%;
    -ms-flex-preferred-size: 41.66667%;
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-ip-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 50%;
    -moz-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-ip-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 58.33333%;
    -moz-flex-basis: 58.33333%;
    -ms-flex-preferred-size: 58.33333%;
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-ip-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 66.66667%;
    -moz-flex-basis: 66.66667%;
    -ms-flex-preferred-size: 66.66667%;
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-ip-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 75%;
    -moz-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-ip-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 83.33333%;
    -moz-flex-basis: 83.33333%;
    -ms-flex-preferred-size: 83.33333%;
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-ip-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 91.66667%;
    -moz-flex-basis: 91.66667%;
    -ms-flex-preferred-size: 91.66667%;
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-ip-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-ip-push-0 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 0; }
  .col-ip-pull-0 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 0; }
  .col-ip-push-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 8.33333%; }
  .col-ip-pull-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 8.33333%; }
  .col-ip-push-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 16.66667%; }
  .col-ip-pull-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 16.66667%; }
  .col-ip-push-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 25%; }
  .col-ip-pull-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 25%; }
  .col-ip-push-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 33.33333%; }
  .col-ip-pull-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 33.33333%; }
  .col-ip-push-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 41.66667%; }
  .col-ip-pull-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 41.66667%; }
  .col-ip-push-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 50%; }
  .col-ip-pull-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 50%; }
  .col-ip-push-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 58.33333%; }
  .col-ip-pull-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 58.33333%; }
  .col-ip-push-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 66.66667%; }
  .col-ip-pull-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 66.66667%; }
  .col-ip-push-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 75%; }
  .col-ip-pull-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 75%; }
  .col-ip-push-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 83.33333%; }
  .col-ip-pull-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 83.33333%; }
  .col-ip-push-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 91.66667%; }
  .col-ip-pull-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 91.66667%; }
  .col-ip-push-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 100%; }
  .col-ip-pull-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 100%; }
  .col-ip {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: 0;
    -moz-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%; }
  .start-ip {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: left; }
  .center-ip {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    text-align: center; }
  .end-ip {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: right; }
  .top-ip {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    align-items: flex-start; }
  .middle-ip {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center; }
  .bottom-ip {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    align-items: flex-end; }
  .around-ip {
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    justify-content: space-around; }
  .between-ip {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
  .first-ip {
    order: -1; }
  .last-ip {
    order: 1; } }

@media only screen and (min-width: 1280px) {
  .container {
    width: 1280px; }
  .col-lg {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: auto;
    -moz-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .col-lg-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 8.33333%;
    -moz-flex-basis: 8.33333%;
    -ms-flex-preferred-size: 8.33333%;
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 16.66667%;
    -moz-flex-basis: 16.66667%;
    -ms-flex-preferred-size: 16.66667%;
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 25%;
    -moz-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-lg-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 33.33333%;
    -moz-flex-basis: 33.33333%;
    -ms-flex-preferred-size: 33.33333%;
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 41.66667%;
    -moz-flex-basis: 41.66667%;
    -ms-flex-preferred-size: 41.66667%;
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 50%;
    -moz-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-lg-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 58.33333%;
    -moz-flex-basis: 58.33333%;
    -ms-flex-preferred-size: 58.33333%;
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 66.66667%;
    -moz-flex-basis: 66.66667%;
    -ms-flex-preferred-size: 66.66667%;
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 75%;
    -moz-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-lg-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 83.33333%;
    -moz-flex-basis: 83.33333%;
    -ms-flex-preferred-size: 83.33333%;
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 91.66667%;
    -moz-flex-basis: 91.66667%;
    -ms-flex-preferred-size: 91.66667%;
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-lg-push-0 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 0; }
  .col-lg-pull-0 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 0; }
  .col-lg-push-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 8.33333%; }
  .col-lg-pull-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 8.33333%; }
  .col-lg-push-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 16.66667%; }
  .col-lg-pull-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 16.66667%; }
  .col-lg-push-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 25%; }
  .col-lg-pull-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 25%; }
  .col-lg-push-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 33.33333%; }
  .col-lg-pull-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 33.33333%; }
  .col-lg-push-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 41.66667%; }
  .col-lg-pull-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 41.66667%; }
  .col-lg-push-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 50%; }
  .col-lg-pull-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 50%; }
  .col-lg-push-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 58.33333%; }
  .col-lg-pull-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 58.33333%; }
  .col-lg-push-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 66.66667%; }
  .col-lg-pull-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 66.66667%; }
  .col-lg-push-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 75%; }
  .col-lg-pull-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 75%; }
  .col-lg-push-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 83.33333%; }
  .col-lg-pull-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 83.33333%; }
  .col-lg-push-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 91.66667%; }
  .col-lg-pull-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 91.66667%; }
  .col-lg-push-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 100%; }
  .col-lg-pull-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 100%; }
  .col-lg {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: 0;
    -moz-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%; }
  .start-lg {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: left; }
  .center-lg {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    text-align: center; }
  .end-lg {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: right; }
  .top-lg {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    align-items: flex-start; }
  .middle-lg {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center; }
  .bottom-lg {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    align-items: flex-end; }
  .around-lg {
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    justify-content: space-around; }
  .between-lg {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
  .first-lg {
    order: -1; }
  .last-lg {
    order: 1; } }

@media only screen and (min-width: 1440px) {
  .container {
    width: 1440px; }
  .col-xl {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: auto;
    -moz-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .col-xl-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 8.33333%;
    -moz-flex-basis: 8.33333%;
    -ms-flex-preferred-size: 8.33333%;
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 16.66667%;
    -moz-flex-basis: 16.66667%;
    -ms-flex-preferred-size: 16.66667%;
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 25%;
    -moz-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-xl-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 33.33333%;
    -moz-flex-basis: 33.33333%;
    -ms-flex-preferred-size: 33.33333%;
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 41.66667%;
    -moz-flex-basis: 41.66667%;
    -ms-flex-preferred-size: 41.66667%;
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 50%;
    -moz-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-xl-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 58.33333%;
    -moz-flex-basis: 58.33333%;
    -ms-flex-preferred-size: 58.33333%;
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 66.66667%;
    -moz-flex-basis: 66.66667%;
    -ms-flex-preferred-size: 66.66667%;
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 75%;
    -moz-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-xl-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 83.33333%;
    -moz-flex-basis: 83.33333%;
    -ms-flex-preferred-size: 83.33333%;
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 91.66667%;
    -moz-flex-basis: 91.66667%;
    -ms-flex-preferred-size: 91.66667%;
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-xl-push-0 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 0; }
  .col-xl-pull-0 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 0; }
  .col-xl-push-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 8.33333%; }
  .col-xl-pull-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 8.33333%; }
  .col-xl-push-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 16.66667%; }
  .col-xl-pull-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 16.66667%; }
  .col-xl-push-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 25%; }
  .col-xl-pull-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 25%; }
  .col-xl-push-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 33.33333%; }
  .col-xl-pull-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 33.33333%; }
  .col-xl-push-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 41.66667%; }
  .col-xl-pull-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 41.66667%; }
  .col-xl-push-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 50%; }
  .col-xl-pull-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 50%; }
  .col-xl-push-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 58.33333%; }
  .col-xl-pull-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 58.33333%; }
  .col-xl-push-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 66.66667%; }
  .col-xl-pull-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 66.66667%; }
  .col-xl-push-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 75%; }
  .col-xl-pull-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 75%; }
  .col-xl-push-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 83.33333%; }
  .col-xl-pull-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 83.33333%; }
  .col-xl-push-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 91.66667%; }
  .col-xl-pull-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 91.66667%; }
  .col-xl-push-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 100%; }
  .col-xl-pull-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 100%; }
  .col-xl {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: 0;
    -moz-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%; }
  .start-xl {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: left; }
  .center-xl {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    text-align: center; }
  .end-xl {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: right; }
  .top-xl {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    align-items: flex-start; }
  .middle-xl {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center; }
  .bottom-xl {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    align-items: flex-end; }
  .around-xl {
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    justify-content: space-around; }
  .between-xl {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
  .first-xl {
    order: -1; }
  .last-xl {
    order: 1; } }

@media only screen and (min-width: 1681px) {
  .container {
    width: 1681px; }
  .col-hg {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: auto;
    -moz-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .col-hg-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 8.33333%;
    -moz-flex-basis: 8.33333%;
    -ms-flex-preferred-size: 8.33333%;
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-hg-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 16.66667%;
    -moz-flex-basis: 16.66667%;
    -ms-flex-preferred-size: 16.66667%;
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-hg-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 25%;
    -moz-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-hg-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 33.33333%;
    -moz-flex-basis: 33.33333%;
    -ms-flex-preferred-size: 33.33333%;
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-hg-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 41.66667%;
    -moz-flex-basis: 41.66667%;
    -ms-flex-preferred-size: 41.66667%;
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-hg-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 50%;
    -moz-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-hg-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 58.33333%;
    -moz-flex-basis: 58.33333%;
    -ms-flex-preferred-size: 58.33333%;
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-hg-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 66.66667%;
    -moz-flex-basis: 66.66667%;
    -ms-flex-preferred-size: 66.66667%;
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-hg-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 75%;
    -moz-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-hg-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 83.33333%;
    -moz-flex-basis: 83.33333%;
    -ms-flex-preferred-size: 83.33333%;
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-hg-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 91.66667%;
    -moz-flex-basis: 91.66667%;
    -ms-flex-preferred-size: 91.66667%;
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-hg-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-hg-push-0 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 0; }
  .col-hg-pull-0 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 0; }
  .col-hg-push-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 8.33333%; }
  .col-hg-pull-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 8.33333%; }
  .col-hg-push-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 16.66667%; }
  .col-hg-pull-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 16.66667%; }
  .col-hg-push-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 25%; }
  .col-hg-pull-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 25%; }
  .col-hg-push-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 33.33333%; }
  .col-hg-pull-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 33.33333%; }
  .col-hg-push-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 41.66667%; }
  .col-hg-pull-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 41.66667%; }
  .col-hg-push-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 50%; }
  .col-hg-pull-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 50%; }
  .col-hg-push-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 58.33333%; }
  .col-hg-pull-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 58.33333%; }
  .col-hg-push-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 66.66667%; }
  .col-hg-pull-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 66.66667%; }
  .col-hg-push-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 75%; }
  .col-hg-pull-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 75%; }
  .col-hg-push-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 83.33333%; }
  .col-hg-pull-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 83.33333%; }
  .col-hg-push-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 91.66667%; }
  .col-hg-pull-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 91.66667%; }
  .col-hg-push-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-left: 100%; }
  .col-hg-pull-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    margin-right: 100%; }
  .col-hg {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: 0;
    -moz-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%; }
  .start-hg {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: left; }
  .center-hg {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    text-align: center; }
  .end-hg {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: right; }
  .top-hg {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    align-items: flex-start; }
  .middle-hg {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center; }
  .bottom-hg {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    align-items: flex-end; }
  .around-hg {
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    justify-content: space-around; }
  .between-hg {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
  .first-hg {
    order: -1; }
  .last-hg {
    order: 1; } }

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 10px 10px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }

.hamburger-box {
  width: 36px;
  height: 23px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 36px;
    height: 3px;
    background-color: #fff;
    border-radius: 0px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -10px; }
  .hamburger-inner::after {
    bottom: -10px; }

/*
   * 3DX
   */
.hamburger--3dx .hamburger-box {
  perspective: 72px; }

.hamburger--3dx .hamburger-inner {
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateY(180deg); }
  .hamburger--3dx.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dx.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DX Reverse
   */
.hamburger--3dx-r .hamburger-box {
  perspective: 72px; }

.hamburger--3dx-r .hamburger-inner {
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dx-r .hamburger-inner::before, .hamburger--3dx-r .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx-r.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateY(-180deg); }
  .hamburger--3dx-r.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dx-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DY
   */
.hamburger--3dy .hamburger-box {
  perspective: 72px; }

.hamburger--3dy .hamburger-inner {
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dy .hamburger-inner::before, .hamburger--3dy .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateX(-180deg); }
  .hamburger--3dy.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dy.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DY Reverse
   */
.hamburger--3dy-r .hamburger-box {
  perspective: 72px; }

.hamburger--3dy-r .hamburger-inner {
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dy-r .hamburger-inner::before, .hamburger--3dy-r .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy-r.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateX(180deg); }
  .hamburger--3dy-r.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dy-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * Arrow
   */
.hamburger--arrow.is-active .hamburger-inner::before {
  transform: translate3d(-7.2px, 0, 0) rotate(-45deg) scale(0.7, 1); }

.hamburger--arrow.is-active .hamburger-inner::after {
  transform: translate3d(-7.2px, 0, 0) rotate(45deg) scale(0.7, 1); }

/*
   * Arrow Right
   */
.hamburger--arrow-r.is-active .hamburger-inner::before {
  transform: translate3d(7.2px, 0, 0) rotate(45deg) scale(0.7, 1); }

.hamburger--arrow-r.is-active .hamburger-inner::after {
  transform: translate3d(7.2px, 0, 0) rotate(-45deg) scale(0.7, 1); }

/*
   * Arrow Alt
   */
.hamburger--arrowalt .hamburger-inner::before {
  transition: top 0.1s 0.15s ease, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt .hamburger-inner::after {
  transition: bottom 0.1s 0.15s ease, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(-7.2px, -9px, 0) rotate(-45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.15s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(-7.2px, 9px, 0) rotate(45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.15s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

/*
   * Arrow Alt Right
   */
.hamburger--arrowalt-r .hamburger-inner::before {
  transition: top 0.1s 0.15s ease, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r .hamburger-inner::after {
  transition: bottom 0.1s 0.15s ease, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(7.2px, -9px, 0) rotate(45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.15s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(7.2px, 9px, 0) rotate(-45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.15s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

/*
   * Boring
   */
.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner::before, .hamburger--boring .hamburger-inner::after {
  transition-property: none; }

.hamburger--boring.is-active .hamburger-inner {
  transform: rotate(45deg); }
  .hamburger--boring.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--boring.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg); }

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.15s;
  transition-delay: 0.15s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--collapse .hamburger-inner::after {
    top: -20px;
    transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  .hamburger--collapse .hamburger-inner::before {
    transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.32s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--collapse.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.27s linear; }
  .hamburger--collapse.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-90deg);
    transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.42s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Collapse Reverse
   */
.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.15s;
  transition-delay: 0.15s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--collapse-r .hamburger-inner::after {
    top: -20px;
    transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  .hamburger--collapse-r .hamburger-inner::before {
    transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(45deg);
  transition-delay: 0.32s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--collapse-r.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.27s linear; }
  .hamburger--collapse-r.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(90deg);
    transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.42s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Elastic
   */
.hamburger--elastic .hamburger-inner {
  top: 1.5px;
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic .hamburger-inner::before {
    top: 10px;
    transition: opacity 0.15s 0.4s ease; }
  .hamburger--elastic .hamburger-inner::after {
    top: 20px;
    transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(135deg);
  transition-delay: 0.1s; }
  .hamburger--elastic.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0; }
  .hamburger--elastic.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(-270deg);
    transition-delay: 0.1s; }

/*
   * Elastic Reverse
   */
.hamburger--elastic-r .hamburger-inner {
  top: 1.5px;
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic-r .hamburger-inner::before {
    top: 10px;
    transition: opacity 0.15s 0.4s ease; }
  .hamburger--elastic-r .hamburger-inner::after {
    top: 20px;
    transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(-135deg);
  transition-delay: 0.1s; }
  .hamburger--elastic-r.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0; }
  .hamburger--elastic-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(270deg);
    transition-delay: 0.1s; }

/*
   * Emphatic
   */
.hamburger--emphatic {
  overflow: hidden; }
  .hamburger--emphatic .hamburger-inner {
    transition: background-color 0.2s 0.25s ease-in; }
    .hamburger--emphatic .hamburger-inner::before {
      left: 0;
      transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, left 0.2s 0.25s ease-in; }
    .hamburger--emphatic .hamburger-inner::after {
      top: 10px;
      right: 0;
      transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, right 0.2s 0.25s ease-in; }
  .hamburger--emphatic.is-active .hamburger-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent; }
    .hamburger--emphatic.is-active .hamburger-inner::before {
      left: -72px;
      top: -72px;
      transform: translate3d(72px, 72px, 0) rotate(45deg);
      transition: left 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1); }
    .hamburger--emphatic.is-active .hamburger-inner::after {
      right: -72px;
      top: -72px;
      transform: translate3d(-72px, 72px, 0) rotate(-45deg);
      transition: right 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
   * Emphatic Reverse
   */
.hamburger--emphatic-r {
  overflow: hidden; }
  .hamburger--emphatic-r .hamburger-inner {
    transition: background-color 0.2s 0.25s ease-in; }
    .hamburger--emphatic-r .hamburger-inner::before {
      left: 0;
      transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, left 0.2s 0.25s ease-in; }
    .hamburger--emphatic-r .hamburger-inner::after {
      top: 10px;
      right: 0;
      transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, right 0.2s 0.25s ease-in; }
  .hamburger--emphatic-r.is-active .hamburger-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent; }
    .hamburger--emphatic-r.is-active .hamburger-inner::before {
      left: -72px;
      top: 72px;
      transform: translate3d(72px, -72px, 0) rotate(-45deg);
      transition: left 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1); }
    .hamburger--emphatic-r.is-active .hamburger-inner::after {
      right: -72px;
      top: 72px;
      transform: translate3d(-72px, -72px, 0) rotate(45deg);
      transition: right 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
   * Slider
   */
.hamburger--slider .hamburger-inner {
  top: 1.5px; }
  .hamburger--slider .hamburger-inner::before {
    top: 10px;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.2s; }
  .hamburger--slider .hamburger-inner::after {
    top: 20px; }

.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--slider.is-active .hamburger-inner::before {
    transform: rotate(-45deg) translate3d(-5.14286px, -7px, 0);
    opacity: 0; }
  .hamburger--slider.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(-90deg); }

/*
   * Slider Reverse
   */
.hamburger--slider-r .hamburger-inner {
  top: 1.5px; }
  .hamburger--slider-r .hamburger-inner::before {
    top: 10px;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.2s; }
  .hamburger--slider-r .hamburger-inner::after {
    top: 20px; }

.hamburger--slider-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(-45deg); }
  .hamburger--slider-r.is-active .hamburger-inner::before {
    transform: rotate(45deg) translate3d(5.14286px, -7px, 0);
    opacity: 0; }
  .hamburger--slider-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(90deg); }

/*
   * Spring
   */
.hamburger--spring .hamburger-inner {
  top: 1.5px;
  transition: background-color 0s 0.15s linear; }
  .hamburger--spring .hamburger-inner::before {
    top: 10px;
    transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spring .hamburger-inner::after {
    top: 20px;
    transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.32s;
  background-color: transparent; }
  .hamburger--spring.is-active .hamburger-inner::before {
    top: 0;
    transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.32s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--spring.is-active .hamburger-inner::after {
    top: 0;
    transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.32s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 10px, 0) rotate(-45deg); }

/*
   * Spring Reverse
   */
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.15s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spring-r .hamburger-inner::after {
    top: -20px;
    transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear; }
  .hamburger--spring-r .hamburger-inner::before {
    transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.32s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spring-r.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.32s linear; }
  .hamburger--spring-r.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(90deg);
    transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.32s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Stand
   */
.hamburger--stand .hamburger-inner {
  transition: transform 0.1s 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.1s linear; }
  .hamburger--stand .hamburger-inner::before {
    transition: top 0.1s 0.1s ease-in, transform 0.1s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand .hamburger-inner::after {
    transition: bottom 0.1s 0.1s ease-in, transform 0.1s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand.is-active .hamburger-inner {
  transform: rotate(90deg);
  background-color: transparent;
  transition: transform 0.1s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.22s linear; }
  .hamburger--stand.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-45deg);
    transition: top 0.1s 0.12s ease-out, transform 0.1s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(45deg);
    transition: bottom 0.1s 0.12s ease-out, transform 0.1s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Stand Reverse
   */
.hamburger--stand-r .hamburger-inner {
  transition: transform 0.1s 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.1s linear; }
  .hamburger--stand-r .hamburger-inner::before {
    transition: top 0.1s 0.1s ease-in, transform 0.1s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand-r .hamburger-inner::after {
    transition: bottom 0.1s 0.1s ease-in, transform 0.1s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand-r.is-active .hamburger-inner {
  transform: rotate(-90deg);
  background-color: transparent;
  transition: transform 0.1s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.22s linear; }
  .hamburger--stand-r.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-45deg);
    transition: top 0.1s 0.12s ease-out, transform 0.1s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand-r.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(45deg);
    transition: bottom 0.1s 0.12s ease-out, transform 0.1s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Spin
   */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin .hamburger-inner::before {
    transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin .hamburger-inner::after {
    transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.14s ease-out; }
  .hamburger--spin.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Spin Reverse
   */
.hamburger--spin-r .hamburger-inner {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin-r .hamburger-inner::before {
    transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin-r .hamburger-inner::after {
    transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin-r.is-active .hamburger-inner {
  transform: rotate(-225deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin-r.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.14s ease-out; }
  .hamburger--spin-r.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--squeeze .hamburger-inner::before {
    transition: top 0.1s 0.14s ease, opacity 0.1s ease; }
  .hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease, opacity 0.1s 0.14s ease; }
  .hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Vortex
   */
.hamburger--vortex .hamburger-inner {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
    transition-duration: 0s;
    transition-delay: 0.1s;
    transition-timing-function: linear; }
  .hamburger--vortex .hamburger-inner::before {
    transition-property: top, opacity; }
  .hamburger--vortex .hamburger-inner::after {
    transition-property: bottom, transform; }

.hamburger--vortex.is-active .hamburger-inner {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
    transition-delay: 0s; }
  .hamburger--vortex.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--vortex.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(90deg); }

/*
   * Vortex Reverse
   */
.hamburger--vortex-r .hamburger-inner {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex-r .hamburger-inner::before, .hamburger--vortex-r .hamburger-inner::after {
    transition-duration: 0s;
    transition-delay: 0.1s;
    transition-timing-function: linear; }
  .hamburger--vortex-r .hamburger-inner::before {
    transition-property: top, opacity; }
  .hamburger--vortex-r .hamburger-inner::after {
    transition-property: bottom, transform; }

.hamburger--vortex-r.is-active .hamburger-inner {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex-r.is-active .hamburger-inner::before, .hamburger--vortex-r.is-active .hamburger-inner::after {
    transition-delay: 0s; }
  .hamburger--vortex-r.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--vortex-r.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg); }

/*  ==========================================================================
	#Global
	======================================================================= */
html {
  height: 100%;
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

body {
  position: relative;
  min-height: 100%;
  background-color: #d5d6d9; }

.wrapper {
  width: 100%;
  overflow: hidden;
  position: relative;
  max-width: 2560px;
  margin: 0 auto; }

.container {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  display: flex; }

.tablet-down {
  display: block; }
  @media screen and (min-width: 1024px) {
    .tablet-down {
      display: none; } }

.tablet-up {
  display: none !important; }
  @media screen and (min-width: 1024px) {
    .tablet-up {
      display: block !important; } }

.xlarge-down {
  display: block; }
  @media screen and (min-width: 1440px) {
    .xlarge-down {
      display: none; } }

.large-down {
  display: block; }
  @media screen and (min-width: 1280px) {
    .large-down {
      display: none; } }

.large-up {
  display: none; }
  @media screen and (min-width: 1280px) {
    .large-up {
      display: block; } }

.medium-up {
  display: none; }
  @media screen and (min-width: 768px) {
    .medium-up {
      display: block; } }

.small-only, .mobile-pagination {
  display: block; }
  @media screen and (min-width: 768px) {
    .small-only, .mobile-pagination {
      display: none; } }

#goog-wm ul {
  padding: 0; }

#goog-wm ul li {
  padding-left: 0; }

#goog-wm ul li:before {
  display: none; }

#goog-wm input[type="submit"] {
  margin-top: 1.5rem; }

/*  ==========================================================================
	##Inset
	======================================================================= */
/*  ==========================================================================
	##Icons
	======================================================================= */
.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor; }

/*  ==========================================================================
	##Bg Colours
	======================================================================= */
.bg--white {
  background-color: #fff; }

.bg--black {
  background-color: #000; }

.bg--light-grey {
  background-color: #ebebed; }

.bg--mid-grey {
  background-color: #d5d6d9; }

.bg--dark-grey {
  background-color: #2e3241; }

/*  ==========================================================================
	#User Interface
	======================================================================= */
/*  ==========================================================================
	##Buttons
	======================================================================= */
.btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #ebebed;
  display: inline-block;
  vertical-align: middle;
  color: #2e3241;
  border: 1px solid;
  padding: 0.5em;
  line-height: 1.2;
  border-radius: 5px;
  transition: all 300ms ease-in-out; }
  .btn:hover, .btn:focus {
    outline: 0;
    background-color: #d5d6d9; }
  .btn--white {
    color: #fff;
    background-color: #2e3241; }
    .btn--white:hover, .btn--white:focus {
      background-color: #232732; }
  .btn--grey {
    border-color: #ebebed;
    padding: 0.75em; }
  .btn--icon {
    display: inline-block;
    position: relative;
    min-width: 16.25rem;
    padding: 0.75em 2em 0.75em 0.75em; }
    .btn--icon .icon {
      position: absolute;
      right: 0.5em; }
  .btn--outline {
    display: block;
    background-color: transparent;
    border-color: #d5d6d9;
    position: relative;
    padding: 0.75em 2em 0.75em 0.75em; }
    @media screen and (min-width: 600px) {
      .btn--outline {
        display: inline-block;
        min-width: 8.125em; } }
    .btn--outline .icon {
      position: absolute;
      right: 0.5em; }
  .btn[data-toggled="true"] .btn__arrow {
    transform: rotate(90deg); }
  .btn__arrow {
    display: inline-block;
    transition: transform 300ms; }

/*  ==========================================================================
	##Dropdowns
	======================================================================= */
.dropdown__wrapper {
  display: flex; }

.dropdown__label {
  color: #2e3241;
  display: block;
  margin-bottom: 0.35em; }

.dropdown__select {
  background-color: transparent;
  color: #2e3241;
  border-radius: 5px;
  border: 1px solid;
  line-height: 1.2;
  padding: 0.5em;
  flex-grow: 1;
  max-width: 100%; }

.dropdown__input {
  width: 100%;
  appearance: none;
  background-color: transparent;
  color: #2e3241;
  border-radius: 5px;
  border: 1px solid #d5d6d9;
  min-height: 36px;
  padding: 0.5em;
  line-height: 1.42857143;
  transition: border 200ms; }
  .dropdown__input:focus {
    outline: 0;
    border-color: #2e3241; }

.dropdown .v-select {
  display: block;
  width: 100%; }
  .dropdown .v-select.open .dropdown-toggle {
    border-color: #2e3241;
    border-bottom-width: 0; }
  .dropdown .v-select.open .dropdown-menu {
    border-color: #2e3241; }
  .dropdown .v-select .dropdown-toggle {
    min-height: 36px;
    border: 1px solid #d5d6d9;
    transition: border-color 200ms; }
  .dropdown .v-select input[type=search],
  .dropdown .v-select input[type=search]:focus {
    color: #2e3241;
    border-radius: 5px; }
    .dropdown .v-select input[type=search]::placeholder,
    .dropdown .v-select input[type=search]:focus::placeholder {
      color: #2e3241; }
  .dropdown .v-select.unsearchable input[type=search] {
    max-width: none; }
  .dropdown .v-select .selected-tag {
    background-color: transparent;
    border: 0;
    margin: 0;
    padding: 0 .5em;
    margin-top: 0.25em;
    pointer-events: none; }
    .dropdown .v-select .selected-tag + input {
      width: 10px !important; }
  .dropdown .v-select .open-indicator {
    height: 17px; }
    .dropdown .v-select .open-indicator:before {
      height: 0;
      width: 0;
      border-color: #2e3241 #2e3241 transparent transparent;
      border-width: 3px; }
  .dropdown .v-select .dropdown-menu .highlight a {
    background-color: rgba(46, 50, 65, 0.2);
    color: #2e3241; }

.dropdown__btn {
  margin-left: 10px; }

.dropdown--nav {
  margin: 1.5rem 0; }
  .dropdown--nav .dropdown__label {
    color: #fff; }
  .dropdown--nav .dropdown__select {
    color: #fff; }
  .dropdown--nav .v-select .dropdown-toggle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .dropdown--nav .v-select .selected-tag {
    color: #fff; }
    .dropdown--nav .v-select .selected-tag + input {
      width: 100% !important;
      position: absolute !important;
      top: 0;
      left: 0; }
  .dropdown--nav .v-select input[type="search"] {
    color: #fff !important; }
  .dropdown--nav .v-select .open-indicator {
    height: auto;
    background-color: #2e3241;
    bottom: 1px;
    top: 1px;
    right: 1px;
    width: 25px;
    border-radius: 5px;
    text-align: center; }
    .dropdown--nav .v-select .open-indicator:before {
      vertical-align: middle;
      border-color: #fff #fff transparent transparent; }
  .dropdown--nav .v-select.open .dropdown-toggle {
    border-color: #fff;
    border-bottom-width: 0; }
  .dropdown--nav .v-select.open .dropdown-menu {
    border-color: #fff; }

.dropdown--topnav {
  display: flex;
  align-items: flex-start;
  margin-top: 0; }
  .dropdown--topnav .icon {
    color: #2e3241; }
  .dropdown--topnav .dropdown__label {
    margin-bottom: 0;
    margin-right: 0.5em; }
  .dropdown--topnav .dropdown__select {
    border: 0; }
  .dropdown--topnav .dropdown__wrapper {
    flex-grow: 1; }

/*  ==========================================================================
	##Legend
	======================================================================= */
.legend {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between; }
  .legend__title {
    flex-basis: 1.65em;
    height: 1.65em;
    border-radius: 50%;
    line-height: 1;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400; }
    .legend__title--m {
      background-color: #329664; }
    .legend__title--v {
      background-color: #64c87d; }
    .legend__title--t {
      background-color: #ffc864; }
    .legend__title--r {
      background-color: #fa5050; }
    .legend__title--p {
      background-color: #c83232; }
  .legend__definition {
    flex-basis: calc(100% - 3.5em);
    flex-grow: 0;
    color: #fff; }
  .legend__title, .legend__definition {
    margin: 0 0 1em; }

/*  ==========================================================================
	## Pillar
	======================================================================= */
.pillar {
  border-radius: 50%;
  line-height: 1;
  text-align: center;
  font-weight: 400;
  display: inline-block; }
  .pillar--small {
    color: #2e3241;
    background-color: #ebebed;
    width: 1.65em;
    height: 1.65em;
    padding: 0.325em 0; }
  .pillar--data-point {
    color: #2e3241;
    background-color: #ebebed;
    width: 2.22em;
    height: 2.22em;
    vertical-align: middle;
    padding: 0.61em 0; }
  .pillar--large {
    color: #fff;
    width: 1.82em;
    height: 1.82em;
    padding: 0.41em 0; }
  .pillar--m {
    background-color: #329664; }
  .pillar--v {
    background-color: #64c87d; }
  .pillar--t {
    background-color: #ffc864; }
  .pillar--r {
    background-color: #fa5050; }
  .pillar--p {
    background-color: #c83232; }
  .pillar__stats {
    display: flex;
    flex-wrap: wrap;
    margin-left: 0;
    margin-right: 0; }
    .pillar__stats + div {
      height: auto !important; }
  .pillar__stat {
    display: flex;
    border-top: 1px solid #ebebed;
    padding-top: 0.5em;
    margin-bottom: 1.5em;
    padding-left: 2em;
    padding-right: 2em; }
    .pillar__stat-title {
      order: 2;
      margin: 0;
      font-weight: 400; }
    .pillar__stat-value {
      font-weight: 500;
      margin-right: 1em; }
    .pillar__stat:nth-of-type(odd) {
      padding-left: 0; }
    .pillar__stat:nth-of-type(even) {
      padding-right: 0; }
    @media screen and (max-width: 599px) {
      .pillar__stat {
        padding-left: 0;
        padding-right: 0; } }

/*  ==========================================================================
	## Score guide
	======================================================================= */
.scoreguide {
  display: flex;
  padding-top: 0.75em;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  position: relative; }
  .scoreguide:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0.9em;
    right: 0.9em;
    border-top: 1px solid #ebebed; }
  .scoreguide__heading {
    font-weight: 400;
    margin: 0;
    margin-bottom: 1em; }
    @media screen and (min-width: 1280px) {
      .scoreguide__heading {
        margin-bottom: 0; } }
  .scoreguide__score {
    display: flex;
    flex-direction: column; }
    @media screen and (max-width: 599px) {
      .scoreguide__score {
        flex-direction: row;
        padding-bottom: 0.25em;
        border-bottom: 1px solid #ebebed;
        margin-bottom: 0.25em; }
        .scoreguide__score .scoreguide__value {
          margin-right: 1.5em; } }
  .scoreguide__title {
    order: 2;
    margin: 0;
    font-weight: 400; }

/*  ==========================================================================
	## Guide
	======================================================================= */
.guide {
  padding-top: 2.5rem;
  padding-bottom: 4rem;
  border-bottom: 2px solid; }
  .guide__gradient {
    display: flex;
    justify-content: space-between;
    margin-left: 8rem;
    margin-bottom: 1.25rem;
    border-radius: 5px;
    background: linear-gradient(to left, #c83232 0%, #fa5050 25%, #ffc864 50%, #64c87d 75%, #329664 100%); }
    .guide__gradient-point {
      color: #fff;
      padding: 0.75em 1em;
      line-height: 1; }
    @media screen and (max-width: 767px) {
      .guide__gradient {
        display: none; } }
  @media screen and (min-width: 480px) and (max-width: 767px) {
    .guide__wrapper {
      display: flex;
      flex-wrap: wrap; }
      .guide__wrapper .guide__row {
        width: 50%; } }
  .guide__row {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 1em 0;
    min-height: 2.22em; }
    .guide__row .pillar {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 20; }
    @media screen and (max-width: 479px) {
      .guide__row {
        margin: 1.5em 0; } }
    @media screen and (max-width: 767px) {
      .guide__row {
        flex-direction: column; }
        .guide__row .pillar {
          position: relative; } }
    @media screen and (min-width: 768px) {
      .guide__row {
        padding-left: 8rem;
        align-items: center; }
        .guide__row:before {
          content: '';
          position: absolute;
          z-index: 10;
          border-bottom: 1px solid #ebebed;
          left: 0;
          right: 0;
          top: 50%; } }
  .guide__text {
    position: relative;
    z-index: 20; }
    @media screen and (max-width: 767px) {
      .guide__text {
        margin: 0.5em 0; }
        .guide__text:before {
          color: #fff;
          border-radius: 50%;
          line-height: 1;
          text-align: center;
          font-weight: 400;
          display: inline-block;
          width: 2.22em;
          height: 2.22em;
          vertical-align: middle;
          padding: 0.71em 0;
          margin-right: 0.5em; }
        .guide__text--high:before {
          content: '6.0';
          background-color: #329664; }
        .guide__text--mid:before {
          content: '3.0';
          background-color: #ffc864; }
        .guide__text--low:before {
          content: '0.0';
          background-color: #c83232; } }
    @media screen and (min-width: 768px) {
      .guide__text {
        min-width: 10em;
        background-color: #fff;
        padding-left: 1em;
        padding-right: 1em; }
        .guide__text--high {
          text-align: left; }
        .guide__text--mid {
          text-align: center; }
        .guide__text--low {
          text-align: right;
          margin-right: 1em; } }

/*  ==========================================================================
	## Glossary
	======================================================================= */
.glossary {
  margin: 0; }
  .glossary__row {
    display: flex;
    padding-top: 0.75em;
    border-top: 1px solid #ebebed;
    margin-bottom: 1.5em; }
  .glossary__title {
    font-weight: 500;
    margin: 0;
    flex: 1 1 22%; }
  .glossary__value {
    flex: 1 1 78%;
    padding-left: 1em; }
  @media screen and (max-width: 479px) {
    .glossary__row {
      flex-direction: column; }
    .glossary__value {
      padding-left: 0; } }

/*  ==========================================================================
	## Metrics
	======================================================================= */
.metrics {
  display: flex;
  margin-top: 4rem;
  margin-left: -2rem;
  margin-right: -2rem; }
  .metrics__col {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0.5rem;
    margin-bottom: 1.5em;
    position: relative; }
    .metrics__col:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 2rem;
      right: 2rem;
      border-top: 2px solid #2e3241; }
  .metrics__heading {
    font-weight: 400;
    margin: 0;
    margin-bottom: 1.5em; }
  .metrics__row {
    display: flex;
    margin-bottom: 0.25em; }
  .metrics__title {
    order: 2;
    margin: 0;
    font-weight: 400;
    margin-left: 1em; }
  .metrics__extra {
    order: 3;
    flex: 1 1 auto;
    text-align: right; }

/*  ==========================================================================
	## Summary List
	======================================================================= */
.summary-list {
  display: flex; }
  .summary-list__value {
    margin: 0;
    font-weight: 500;
    min-width: 30px;
    text-align: right; }
  .summary-list__label {
    margin-left: 1.5em; }
  .summary-list__title {
    font-weight: 400;
    margin: 0; }
  .summary-list--titled {
    margin-top: 0.25rem;
    margin-bottom: 1.5rem; }

/*  ==========================================================================
	## Rank table
	======================================================================= */
.rank-table {
  margin-bottom: 2.5rem; }
  .rank-table thead th {
    font-weight: 400;
    padding-bottom: 1em; }
  .rank-table tbody th,
  .rank-table tbody td {
    padding-top: 0.75em;
    padding-bottom: 0.75em;
    border-bottom: 0;
    border-top: 1px solid #ebebed; }
  .rank-table__rank {
    font-weight: 500;
    padding-right: 1em; }
  .rank-table__value {
    text-align: center; }
  .rank-table__text {
    padding-right: 1em; }
    .rank-table__text--center {
      text-align: center; }
    .rank-table__text a {
      display: inline-block;
      position: relative; }
      .rank-table__text a:after {
        content: '';
        display: inline-block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        border-bottom: 1px solid transparent;
        transition: border 200ms; }
      .rank-table__text a:hover:after {
        border-bottom-color: #2e3241; }
    .rank-table__text:last-child {
      padding-left: 1em;
      padding-right: 0; }
  .rank-table.tablesaw-swipe .tablesaw-swipe-cellpersist {
    border-right: 0; }
    .rank-table.tablesaw-swipe .tablesaw-swipe-cellpersist + .tablesaw-swipe-cellpersist {
      border-right: 2px solid #ebebed; }

.tablesaw-all-cols-visible .tablesaw-advance {
  display: none; }

.tablesaw-all-cols-visible + .tablesaw-swipe .tablesaw-swipe-cellpersist {
  border-right: 0 !important; }

/*  ==========================================================================
	## Data point
	======================================================================= */
.bg-0 {
  background-color: #d2383e; }

.bg-1 {
  background-color: #d2383e; }

.bg-2 {
  background-color: #d2383e; }

.bg-3 {
  background-color: #d2383e; }

.bg-4 {
  background-color: #d2383e; }

.bg-5 {
  background-color: #e23d47; }

.bg-6 {
  background-color: #e23d47; }

.bg-7 {
  background-color: #e23d47; }

.bg-8 {
  background-color: #e23d47; }

.bg-9 {
  background-color: #e23d47; }

.bg-10 {
  background-color: #f24950; }

.bg-11 {
  background-color: #f24950; }

.bg-12 {
  background-color: #f24950; }

.bg-13 {
  background-color: #f24950; }

.bg-14 {
  background-color: #f24950; }

.bg-15 {
  background-color: #fb5e58; }

.bg-16 {
  background-color: #fb5e58; }

.bg-17 {
  background-color: #fb5e58; }

.bg-18 {
  background-color: #fb5e58; }

.bg-19 {
  background-color: #fb5e58; }

.bg-20 {
  background-color: #fd825e; }

.bg-21 {
  background-color: #fd825e; }

.bg-22 {
  background-color: #fd825e; }

.bg-23 {
  background-color: #fd825e; }

.bg-24 {
  background-color: #fd825e; }

.bg-25 {
  background-color: #fda965; }

.bg-26 {
  background-color: #fda965; }

.bg-27 {
  background-color: #fda965; }

.bg-28 {
  background-color: #fda965; }

.bg-29 {
  background-color: #fda965; }

.bg-30 {
  background-color: #fec66c; }

.bg-31 {
  background-color: #fec66c; }

.bg-32 {
  background-color: #fec66c; }

.bg-33 {
  background-color: #fec66c; }

.bg-34 {
  background-color: #fec66c; }

.bg-35 {
  background-color: #dcc772; }

.bg-36 {
  background-color: #dcc772; }

.bg-37 {
  background-color: #dcc772; }

.bg-38 {
  background-color: #dcc772; }

.bg-39 {
  background-color: #dcc772; }

.bg-40 {
  background-color: #a9c779; }

.bg-41 {
  background-color: #a9c779; }

.bg-42 {
  background-color: #a9c779; }

.bg-43 {
  background-color: #a9c779; }

.bg-44 {
  background-color: #a9c779; }

.bg-45 {
  background-color: #79c77f; }

.bg-46 {
  background-color: #79c77f; }

.bg-47 {
  background-color: #79c77f; }

.bg-48 {
  background-color: #79c77f; }

.bg-49 {
  background-color: #79c77f; }

.bg-50 {
  background-color: #5bc27e; }

.bg-51 {
  background-color: #5bc27e; }

.bg-52 {
  background-color: #5bc27e; }

.bg-53 {
  background-color: #5bc27e; }

.bg-54 {
  background-color: #5bc27e; }

.bg-55 {
  background-color: #49b276; }

.bg-56 {
  background-color: #49b276; }

.bg-57 {
  background-color: #49b276; }

.bg-58 {
  background-color: #49b276; }

.bg-59 {
  background-color: #49b276; }

.bg-60 {
  background-color: #3fa16d; }

.data-point {
  display: inline-block;
  width: 2.22em;
  height: 2.22em;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  line-height: 1;
  padding: 0.61em 0;
  margin: 0 0.4em; }

/*  ==========================================================================
	## Outlook
	======================================================================= */
.outlook__item {
  display: flex;
  margin-bottom: 2.5rem;
  align-items: center; }

.outlook__pillar {
  flex-shrink: 0; }

.outlook__content {
  padding-left: 1.5em; }
  .outlook__content p {
    margin: 0; }

/*  ==========================================================================
	## Radar
	======================================================================= */
.radar__legend + .scoreguide, .scatter__legend + .scoreguide {
  margin-top: 4rem; }

.radar__legend ul, .scatter__legend ul {
  display: flex;
  justify-content: space-around; }
  @media screen and (max-width: 599px) {
    .radar__legend ul, .scatter__legend ul {
      flex-direction: column; }
      .radar__legend ul li, .scatter__legend ul li {
        margin-bottom: 1em; } }

.radar__legend li, .scatter__legend li {
  cursor: pointer;
  display: flex;
  line-height: 1.5; }

.radar__legend--hidden, .scatter__legend--hidden {
  color: rgba(46, 50, 65, 0.5); }
  .radar__legend--hidden .radar__swatch:before, .scatter__legend--hidden .radar__swatch:before, .radar__legend--hidden .scatter__swatch:before, .scatter__legend--hidden .scatter__swatch:before {
    opacity: 0.5; }
  .radar__legend--hidden .radar__swatch:after, .scatter__legend--hidden .radar__swatch:after, .radar__legend--hidden .scatter__swatch:after, .scatter__legend--hidden .scatter__swatch:after {
    right: 1em;
    opacity: 0.5; }

#scores + .radar__legend, #scores + .scatter__legend {
  margin-top: 4rem; }

.radar__swatch, .scatter__swatch {
  display: inline-block;
  margin-right: 1em;
  margin-top: 0.25em;
  position: relative;
  height: 0;
  box-sizing: content-box; }
  .radar__swatch:before, .scatter__swatch:before {
    content: '';
    display: inline-block;
    position: relative;
    left: 0;
    width: 2em;
    height: calc(1em + 2px);
    border-radius: 1em;
    border: 1px solid; }
  .radar__swatch:after, .scatter__swatch:after {
    content: '';
    display: inline-block;
    position: absolute;
    z-index: 10;
    width: 1em;
    height: 1em;
    right: 2px;
    top: 2px;
    border-radius: 1px solid transparent;
    border-radius: 50%;
    background-color: inherit;
    vertical-align: middle;
    transition: right 200ms, opacity 200ms; }

.radar__container--fullscreen {
  padding-left: 3rem;
  padding-right: 3rem; }
  @media screen and (max-width: 767px) {
    .radar__container--fullscreen {
      padding: 0; } }

.scatter__legend {
  margin-top: 2.5rem; }

/*  ==========================================================================
	#Text Elements
	======================================================================= */
/*  ==========================================================================
	##Heading
	======================================================================= */
.heading {
  border-top: 2px solid;
  padding-top: 0.5em;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem; }
  .heading .icon {
    font-size: 1.25em; }
    .heading .icon.binoculars {
      font-size: 1.75em; }
    .heading .icon.graph {
      font-size: 1.5em; }
  .heading--pillar {
    align-items: center; }
    .heading--pillar .heading__text {
      margin-left: 0.5em;
      margin-right: auto; }

/*  ==========================================================================
	##Compare
	======================================================================= */
.compare__status {
  font-style: italic;
  margin-bottom: 2.5rem; }

.compare__select {
  margin-bottom: 2.5rem; }

/*  ==========================================================================
	##Summary
	======================================================================= */
.summary {
  justify-content: space-between;
  margin-bottom: 4rem; }
  .summary:before {
    content: '';
    display: block;
    position: relative;
    width: calc(100% - 1.8rem);
    left: 0.9rem;
    padding-top: 1em;
    border-top: 2px solid #2e3241; }
  .summary__status {
    background-color: #2e3241;
    width: 7.5rem;
    height: 7.5rem;
    margin-left: 0.9rem;
    border-radius: 50%;
    flex-basis: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    @media screen and (max-width: 767px) {
      .summary__status {
        width: 10rem;
        height: 10rem;
        margin-bottom: 2.5rem; } }
  .summary__rank {
    color: #fff;
    font-size: 55px;
    font-weight: 300;
    line-height: 1;
    margin-bottom: 0.1em; }
  .summary__recommendation {
    color: #fff; }
  .summary__links {
    margin-top: 2.5rem; }
    @media screen and (min-width: 1024px) {
      .summary__links {
        margin-top: 0; } }
    .summary__links .summary__link {
      padding-bottom: 0.25em;
      border-bottom: 1px solid #ebebed;
      margin-bottom: 0.75em; }
      .summary__links .summary__link:nth-of-type(2) a:hover,
      .summary__links .summary__link:nth-of-type(2) a:focus {
        color: #64c87d; }
      .summary__links .summary__link:nth-of-type(3) a:hover,
      .summary__links .summary__link:nth-of-type(3) a:focus {
        color: #fa5050; }
      .summary__links .summary__link a {
        display: flex;
        width: 100%;
        align-items: center; }
        .summary__links .summary__link a:hover, .summary__links .summary__link a:focus {
          color: #329664; }
      .summary__links .summary__link span {
        margin-right: 0.5em; }
      .summary__links .summary__link .icon {
        margin-left: auto; }

/*  ==========================================================================
	#Mobile Menu
	======================================================================= */
.mobile-menu {
  background-color: #2e3241;
  display: flex;
  align-items: center;
  margin: -2.5rem;
  margin-bottom: 0;
  padding: 2.5rem; }
  @media screen and (min-width: 768px) {
    .mobile-menu {
      display: none; } }
  .mobile-menu__logo {
    height: 50px;
    margin-right: auto; }
  .mobile-menu__locale, .mobile-menu__account {
    appearance: none;
    background-color: transparent;
    border: 0;
    color: #fff;
    font-size: 36px;
    padding: 10px;
    margin: 0 5px;
    line-height: 1; }
  .mobile-menu .hamburger {
    margin-right: -10px; }

/*  ==========================================================================
	#Side
	======================================================================= */
.side {
  background-color: #2e3241;
  width: 350px;
  flex-shrink: 0;
  transition: width 400ms;
  padding: 2.5rem;
  position: relative; }
  .side__slide {
    display: none;
    cursor: pointer;
    appearance: none;
    border: 0;
    color: #fff;
    position: absolute;
    background-color: transparent;
    right: -12px;
    width: 13px;
    height: 75px;
    padding: 0;
    top: 50vh;
    transform: translateY(-50%); }
    .side__slide:before, .side__slide:after {
      content: '';
      display: block;
      position: absolute;
      height: 20px;
      top: 26px;
      border-left: 1px solid rgba(255, 255, 255, 0.25); }
    .side__slide:before {
      left: 3px; }
    .side__slide:after {
      right: 5px; }
    .side__slide:focus {
      outline: 0; }
  .side__logo {
    margin-bottom: 6.5rem; }
  .side__nav li {
    margin: 1.5em 0; }
  .side__nav a {
    display: block;
    color: #fff;
    position: relative;
    overflow: hidden; }
    .side__nav a:hover .side__nav-arrow, .side__nav a.router-link-active .side__nav-arrow {
      transform: translateX(0); }
    .side__nav a:hover .side__nav-text, .side__nav a.router-link-active .side__nav-text {
      border-bottom-color: #fff; }
    .side__nav a .icon {
      width: 0;
      height: 0;
      transition: width 300ms; }
  .side__nav-text {
    width: auto;
    display: inline-block;
    overflow: hidden;
    border-bottom: 1px solid transparent;
    transition: border 200ms; }
  .side__nav-arrow {
    display: inline-block;
    position: absolute;
    right: 0;
    transform: translateX(100%);
    transition: transform 200ms; }
  .side__panel {
    margin-top: 4rem;
    border-top: 1px solid #fff;
    margin-right: -2.5rem;
    padding-right: 2.5rem; }
  .side__heading {
    font-weight: 400;
    color: #fff;
    margin: 1.5rem 0; }
  @media screen and (max-width: 1439px) {
    .side {
      width: 100px;
      position: absolute;
      z-index: 400;
      left: 0;
      top: 0;
      bottom: 0;
      padding: 2.5rem 1.25rem; }
      .side + .main {
        margin-left: 100px;
        width: calc(100% - 100px); }
      .side--expanded {
        width: 300px !important;
        padding: 2.5rem; }
      .side__slide {
        display: block; }
      .side:not(.side--expanded) .side__nav li {
        text-align: center; }
      .side:not(.side--expanded) .side__nav .icon {
        width: 1.5em;
        height: 1.5em;
        transition: width 300ms; }
      .side:not(.side--expanded) .side__nav span {
        width: 0;
        height: 0;
        overflow: hidden; }
      .side:not(.side--expanded) .side__panel {
        display: none; } }
  @media screen and (max-width: 1023px) {
    .side {
      width: 70px; }
      .side + .main {
        margin-left: 70px;
        width: calc(100% - 70px); } }
  @media screen and (max-width: 767px) {
    .side {
      transform: translateX(300px);
      left: auto;
      right: 0;
      padding-top: 0;
      margin-top: calc(5rem + 62px);
      width: 300px;
      transition: transform 300ms; }
      .side__logo {
        display: none; }
      .side--expanded {
        transform: translate(0); }
      .side + .main {
        margin-left: 0;
        width: 100%; } }

/*  ==========================================================================
	#Main
	======================================================================= */
.main {
  background-color: #fff;
  width: calc(100% - 350px);
  padding: 2.5rem 2.5rem 4rem; }
  @media screen and (min-width: 1440px) {
    .main {
      padding-left: 4rem; } }
  .main .title__heading {
    margin: 0;
    line-height: 1.2; }
  .main .title__timestamp {
    color: #d5d6d9;
    line-height: 1.2; }
  .main__content {
    margin-top: 4rem; }
    .main__content.row {
      margin-left: -2rem;
      margin-right: -2rem; }
      .main__content.row > * {
        padding-left: 2rem;
        padding-right: 2rem; }
    .main__content--dashboard {
      margin-top: 8rem; }

/*  ==========================================================================
	##Topbar
	======================================================================= */
.topbar {
  align-content: flex-start;
  flex-wrap: wrap; }
  @media screen and (min-width: 768px) {
    .topbar {
      display: flex; } }
  .topbar__title {
    width: 100%;
    margin-top: 2.5rem; }
    @media screen and (min-width: 1024px) {
      .topbar__title {
        margin-top: 0; } }
  .topbar__locale, .topbar__user {
    display: none; }
    @media screen and (min-width: 768px) {
      .topbar__locale, .topbar__user {
        display: block; } }
    .topbar__locale .dropdown--nav .v-select .open-indicator, .topbar__user .dropdown--nav .v-select .open-indicator {
      background-color: transparent; }
      .topbar__locale .dropdown--nav .v-select .open-indicator:before, .topbar__user .dropdown--nav .v-select .open-indicator:before {
        border-color: #2e3241 #2e3241 transparent transparent; }
    .topbar__locale .dropdown--nav .v-select .selected-tag, .topbar__user .dropdown--nav .v-select .selected-tag {
      color: #2e3241; }
  .topbar__locale {
    margin-left: 0;
    padding-left: 0; }
  @media screen and (min-width: 1280px) {
    .topbar__title {
      margin-right: auto;
      width: auto; }
    .topbar__locale, .topbar__user {
      order: 2; } }

/*  ==========================================================================
	##Panel
	======================================================================= */
.panel__content {
  margin-bottom: 4rem; }

.panel__filters:before, .panel__filters:after {
  content: '';
  position: relative;
  width: calc(100% - 1.8rem);
  left: 0.9rem; }

.panel__filters:before {
  margin-top: 2.5rem;
  border-top: 2px solid;
  padding-top: 0.5em; }

.panel__filters:after {
  margin-top: 4rem;
  border-bottom: 2px solid; }

.panel__filters .btn__wrapper {
  align-self: flex-end;
  text-align: right; }

.panel--radar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 4rem; }
  .panel--radar .radar__legend, .panel--radar .scatter__legend {
    margin-bottom: auto; }

/*  ==========================================================================
	##Rich text
	======================================================================= */
.panel__rich > *:first-child, .accordion .accordion__content > *:first-child {
  margin-top: 0; }

.panel__rich h3, .accordion .accordion__content h3 {
  font-weight: 500;
  margin: 2em 0 0.5em;
  line-height: 1.2; }

.panel__rich p, .accordion .accordion__content p {
  line-height: 1.6;
  margin-bottom: 1em; }

.panel__rich a, .accordion .accordion__content a {
  text-decoration: underline; }

/*  ==========================================================================
	##Accordion
	======================================================================= */
.accordion .accordion__heading {
  cursor: pointer;
  transition: opacity 300ms; }
  .accordion .accordion__heading:hover {
    opacity: 0.9; }

.accordion .accordion__icon {
  transform: rotateX(0deg);
  transition: transform 1s; }

.accordion[data-open="true"] .accordion__icon {
  transform: rotateX(180deg); }

/*  ==========================================================================
	#Footer
	======================================================================= */
.footer .row {
  background-color: #ebebed;
  padding-bottom: 2.5rem; }

.footer__logo, .footer__disclaimer, .footer__links {
  padding: 2.5rem;
  text-align: center; }
  @media screen and (min-width: 768px) {
    .footer__logo, .footer__disclaimer, .footer__links {
      text-align: left; } }

.footer__logo {
  width: 100%;
  flex-shrink: 0; }
  @media screen and (min-width: 1024px) {
    .footer__logo {
      width: 200px; } }
  @media screen and (min-width: 1440px) {
    .footer__logo {
      width: 350px; } }

.footer__disclaimer {
  padding-left: 2.5rem;
  flex-grow: 0;
  flex-basis: 100%; }
  .footer__disclaimer h2 {
    font-weight: 400;
    margin: 0 0 1em; }
  .footer__disclaimer p {
    line-height: 1.8; }
  .footer__disclaimer a {
    text-decoration: underline; }
  @media screen and (min-width: 768px) {
    .footer__disclaimer {
      flex-basis: 60%; } }
  @media screen and (max-width: 767px) {
    .footer__disclaimer {
      order: 3; } }
  @media screen and (min-width: 1024px) {
    .footer__disclaimer {
      flex-basis: calc((100% - 200px) *.60); } }
  @media screen and (min-width: 1440px) {
    .footer__disclaimer {
      padding-left: 4rem;
      flex-basis: calc((100% - 350px) *.66); } }

.footer__links {
  flex-grow: 0;
  flex-basis: 100%; }
  @media screen and (min-width: 768px) {
    .footer__links {
      flex-basis: 40%; } }
  @media screen and (min-width: 1024px) {
    .footer__links {
      flex-basis: calc((100% - 200px) *.40); } }
  @media screen and (min-width: 1280px) {
    .footer__links ul {
      display: flex;
      justify-content: space-between; } }
  @media screen and (min-width: 1440px) {
    .footer__links {
      flex-basis: calc((100% - 350px) *.33); } }

/*  ==========================================================================
	### Accordion
	======================================================================= */
[data-control] {
  cursor: pointer; }

[data-accordion] [data-content] {
  overflow: hidden;
  max-height: 0; }

.internal__accordion {
  margin: 1rem 0; }
  .internal__article + .internal__accordion {
    margin-top: 2.5rem; }
  .internal__accordion [data-control] {
    position: relative;
    color: #2e3241;
    padding-top: 0.25em;
    padding-right: 1.5em;
    border-top: 1px solid;
    line-height: 1.2em;
    margin: 0;
    transition: color 300ms; }
    .internal__accordion [data-control] .icon {
      position: absolute;
      right: 0;
      top: 0.25em; }
    .internal__accordion [data-control]:hover {
      color: #000; }
  .internal__accordion.open [data-control] {
    color: #000;
    font-weight: 700; }
  .internal__accordion [data-content] {
    margin-top: 1rem;
    padding-right: 1.5rem; }

/*  ==========================================================================
	###Pagination
	======================================================================= */
.pagination {
  text-align: center;
  display: none;
  width: 100%;
  margin-top: 4rem; }
  @media screen and (min-width: 768px) {
    .pagination {
      display: block; } }
  .pagination ul {
    list-style-type: none;
    margin-left: 0;
    width: 100%; }
  .pagination .label {
    display: inline-block;
    width: auto;
    text-align: center;
    line-height: 1; }
    @media screen and (min-width: 768px) {
      .pagination .label {
        max-width: 11.11111%; } }
    .pagination .label.prev a,
    .pagination .label.next a {
      border: 1px solid;
      border-radius: 50%; }
      .pagination .label.prev a:hover, .pagination .label.prev a:focus,
      .pagination .label.next a:hover,
      .pagination .label.next a:focus {
        color: #2e3241; }
  .pagination a,
  .pagination .active span {
    color: #000;
    display: block;
    padding: 1em 1.1em;
    line-height: 1;
    border-radius: 50%;
    transition: border 0.3s 0s; }
  .pagination .active span {
    background-color: #ebebed;
    color: #000; }
  .pagination a:hover,
  .pagination a:active,
  .pagination a:focus {
    text-decoration: none;
    color: #2e3241; }
  .pagination .link:hover {
    border-bottom-color: #2e3241; }
  .pagination .active {
    border-bottom-color: #2e3241; }

.mobile-pagination {
  margin-top: 2.5rem;
  position: relative;
  width: 100%; }
  .mobile-pagination h3 {
    margin: 0;
    margin-bottom: 0.75rem;
    padding-top: 0.25rem;
    border-top: 1px solid #2e3241;
    color: #2e3241; }
  .mobile-pagination select {
    width: 100%;
    color: #000;
    background-color: transparent;
    border: 2px solid #000;
    border-radius: 10px;
    padding: 0.5em;
    line-height: 1.2;
    transition: all 300ms; }
    .mobile-pagination select:focus {
      border-color: #2e3241;
      outline: 0;
      border-radius: 10px 10px 0px 0px; }

/* ==========================================================================
	#Back to top
	========================================================================== */
#back-top {
  display: none;
  position: fixed;
  bottom: 10px;
  right: 10px;
  text-indent: -999em;
  background-color: rgba(255, 255, 255, 0.1);
  width: 40px;
  height: 40px;
  z-index: 10000;
  transition: background-color 500ms; }
  .no-touch #back-top:hover {
    background-color: rgba(255, 255, 255, 0.25); }
  #back-top:after {
    content: '';
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 10px 12px 10px;
    border-color: transparent transparent rgba(255, 255, 255, 0.6) transparent;
    position: absolute;
    top: 13px;
    left: 10px; }
