/*  ==========================================================================
	#Global
	======================================================================= */

html {
	height: 100%;
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
}

body {
	position: relative;
	min-height: 100%;
	background-color: $mid-grey;
}

.wrapper {
	width: 100%;
	overflow:hidden;
	position:relative;

	max-width: 2560px;
	margin: 0 auto;
}

.container {
	width: 100%;
	max-width: 1920px;
	margin: 0 auto;
	position: relative;
	display: flex;
}

.tablet-down {
	display: block;
	@include media($ipad) {
		display: none;
	}
}
.tablet-up {
	display: none !important;
	@include media($ipad) {
		display: block !important;
	}
}
.xlarge-down {
	display: block;
	@include media($xlarge) {
		display: none;
	}
}
.large-down {
	display: block;
	@include media($large) {
		display: none;
	}
}
.large-up {
	display: none;
	@include media($large) {
		display: block;
	}
}
.medium-up {
	display: none;
	@include media($medium) {
		display: block;
	}
}
.small-only {
	display: block;
	@include media($medium) {
		display: none;
	}
}


#goog-wm {

	ul {
		padding: 0;
	}

	ul li {
		padding-left: 0;
	}

	ul li:before {
		display: none;
	}

	input[type="submit"] {
		margin-top: 1.5rem;
	}
}





/*  ==========================================================================
	##Inset
	======================================================================= */

%inset {
	padding-left: 20px;
	padding-right: 20px;

	@include media($small) {
		padding-left: $pad-small;
		padding-right: $pad-small;
	}

	@include media($large) {
		padding-left: $pad-large;
		padding-right: $pad-large;
	}
}

%inset__right {
	right: 20px;

	@include media($small) {
		right: $pad-small;
	}

	@include media($large) {
		right: $pad-large;
	}
}

%inset__left {
	left: 20px;

	@include media($small) {
		left: $pad-small;
	}

	@include media($large) {
		left: $pad-large;
	}
}


/*  ==========================================================================
	##Icons
	======================================================================= */

.icon {
	display: inline-block;
	width: 1em;
	height: 1em;
	fill: currentColor;
}

/*  ==========================================================================
	##Bg Colours
	======================================================================= */

.bg--white {
	background-color: $white;
}

.bg--black {
	background-color: $black;
}

.bg--light-grey {
	background-color: $light-grey;
}

.bg--mid-grey {
	background-color: $mid-grey;
}

.bg--dark-grey {
	background-color: $dark-grey;
}


/*  ==========================================================================
	#User Interface
	======================================================================= */

/*  ==========================================================================
	##Buttons
	======================================================================= */

.btn {
	@extend %base;
	-webkit-appearance: none;
	-moz-appearance: none;
	background-color: $light-grey;
	display: inline-block;
	vertical-align: middle;
	color: $primary;
	border: 1px solid;
	padding: 0.5em;
	line-height: 1.2;
	border-radius: 5px;
	transition: all $base-duration $base-timing;

	&:hover,
	&:focus {
		outline: 0;
		background-color: $mid-grey;
	}

	&--white {
		color: $white;
		background-color: $primary;

		&:hover,
		&:focus {
			// border-color: darken($primary, 5%);
			background-color: darken($primary, 5%);
		}
	}

	&--grey {
		border-color: $light-grey;
		padding: 0.75em;
	}

	&--icon {
		display: inline-block;
		position: relative;
		min-width: 16.25rem;
		padding: 0.75em 2em 0.75em 0.75em;

		.icon {
			position: absolute;
			right: 0.5em;
		}
	}

	&--outline {
		@extend %base;
		display: block;
		background-color: transparent;
		border-color: $mid-grey;
		position: relative;
		padding: 0.75em 2em 0.75em 0.75em;

		@include media($nexus) {
			display: inline-block;
			min-width: 8.125em;
		}

		.icon {
			position: absolute;
			right: 0.5em;
		}
	}

	&[data-toggled="true"] {
		.btn__arrow {
			transform: rotate(90deg);
		}
	}

	&__arrow {
		display: inline-block;
		transition: transform 300ms;
	}
}


/*  ==========================================================================
	##Dropdowns
	======================================================================= */

.dropdown {

	&__wrapper {
		display: flex;
	}

	&__label {
		@extend %delta;
		color: $primary;
		display: block;
		margin-bottom: 0.35em;
	}

	&__select {
		@extend %base;
		background-color: transparent;
		color: $primary;
		border-radius: 5px;
		border: 1px solid;
		line-height: 1.2;
		padding: 0.5em;
		flex-grow: 1;
		max-width: 100%;
	}

	&__input {
		@extend %base;
		width: 100%;
		appearance: none;
		background-color: transparent;
		color: $primary;
		border-radius: 5px;
		border: 1px solid $mid-grey;
		min-height: 36px;
		padding: 0.5em;
		line-height: 1.42857143;
		transition: border 200ms;

		&:focus {
			outline: 0;
			border-color: $primary;
		}
	}

	.v-select {
		display: block;
		width: 100%;

		&.open {
			.dropdown-toggle {
				border-color: $primary;
				border-bottom-width: 0;
			}

			.dropdown-menu {
				border-color: $primary;
			}
		}

		.dropdown-toggle {
			min-height: 36px;
			border: 1px solid $mid-grey;
			transition: border-color 200ms;
		}

		input[type=search],
		input[type=search]:focus {
			@extend %base;
			color: $primary;
			border-radius: 5px;

			&::placeholder {
				color: $primary;
			}
		}

		&.unsearchable input[type=search] {
			max-width: none;
		}

		.selected-tag {
			@extend %base;
			background-color: transparent;
			border: 0;
			margin: 0;
			padding: 0 .5em;
			margin-top: 0.25em;
			pointer-events: none;
			// float: none;

			+ input {
				// display: none;
				width: 10px !important;
			}
		}

		.open-indicator {
			height: 17px;

			&:before {
				height: 0;
				width: 0;
				border-color: $primary $primary transparent transparent;
				border-width: 3px;
			}
		}

		.dropdown-menu {
			@extend %base;

			.highlight a {
				background-color: rgba($primary, 0.2);
				color: $primary;
			}
		}

	}

	&__btn {
		@extend %delta;
		margin-left: 10px;
	}

	&--nav {
		margin: 1.5rem 0;

		.dropdown__label {
			color: $white;
		}

		.dropdown__select {
			color: $white;
		}

		.v-select {

			.dropdown-toggle {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.selected-tag {
				color: $white;

				+ input {
					width: 100% !important;
					position: absolute !important;
					top: 0;
					left: 0;
				}
			}

			input[type="search"] {
				color: $white !important;
			}

			.open-indicator {
				height: auto;
				background-color: #2e3241;
				bottom: 1px;
				top: 1px;
				right: 1px;
				width: 25px;
				border-radius: 5px;
				text-align: center;

				&:before {
					vertical-align: middle;
					border-color: $white $white transparent transparent;
				}
			}

			&.open {
				.dropdown-toggle {
					border-color: $white;
					border-bottom-width: 0;
				}

				.dropdown-menu {
					border-color: $white;
				}
			}

		}
	}

	&--topnav {
		display: flex;
		align-items: flex-start;
		margin-top: 0;

		.icon {
			color: $primary;
			@extend %beta;
		}

		.dropdown__label {
			margin-bottom: 0;
			margin-right: 0.5em;
		}

		.dropdown__select {
			border: 0;
		}

		.dropdown__wrapper {
			flex-grow: 1;
		}


	}
}


/*  ==========================================================================
	##Legend
	======================================================================= */

.legend {
	@extend %delta;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;

	&__title {
		flex-basis: 1.65em;
		height: 1.65em;
		border-radius: 50%;
		line-height: 1;
		color: $white;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 400;

		&--m {
			background-color: $green;
		}

		&--v {
			background-color: $greenLight;
		}

		&--t {
			background-color: $yellow;
		}

		&--r {
			background-color: $orange;
		}

		&--p {
			background-color: $red;
		}
	}

	&__definition {
		@extend %base;
		flex-basis: calc(100% - 3.5em);
		flex-grow: 0;
		color: $white;
	}

	&__title,
	&__definition {
		margin: 0 0 1em;
	}
}




/*  ==========================================================================
	## Pillar
	======================================================================= */

.pillar {
	border-radius: 50%;
	line-height: 1;
	text-align: center;
	font-weight: 400;
	display: inline-block;

	&--small {
		color: $primary;
		background-color: $light-grey;
		width: 1.65em;
		height: 1.65em;
		padding: 0.325em 0;
	}

	&--data-point {
		color: $primary;
		background-color: $light-grey;
		@extend %delta;
		width: 2.22em;
		height: 2.22em;
		vertical-align: middle;
		padding: 0.61em 0;
	}

	&--large {
		@extend %gamma;
		color: $white;
		width: 1.82em;
		height: 1.82em;
		padding: 0.41em 0;
	}

	&--m {
		background-color: $green;
	}

	&--v {
		background-color: $greenLight;
	}

	&--t {
		background-color: $yellow;
	}

	&--r {
		background-color: $orange;
	}

	&--p {
		background-color: $red;
	}



	&__stats {
		display: flex;
		flex-wrap: wrap;
		margin-left: 0;
		margin-right: 0;

		+ div {
			height: auto !important;
		}
	}

	&__stat {
		@extend %delta;
		display: flex;
		border-top: 1px solid $light-grey;
		padding-top: 0.5em;
		margin-bottom: 1.5em;
		padding-left: 2em;
		padding-right: 2em;

		&-title {
			order: 2;
			margin: 0;
			font-weight: 400;
		}

		&-value {
			font-weight: 500;
			margin-right: 1em;
		}

		&:nth-of-type(odd) {
			padding-left: 0;
		}

		&:nth-of-type(even) {
			padding-right: 0;
		}

		@include media-max($nexus) {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

/*  ==========================================================================
	## Score guide
	======================================================================= */

.scoreguide {
	@extend %base;
	display: flex;
	padding-top: 0.75em;
	margin-top: 1.5em;
	margin-bottom: 1.5em;
	position: relative;

	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0.9em;
		right: 0.9em;
		border-top: 1px solid $light-grey;
	}

	&__heading {
		@extend %base;
		font-weight: 400;
		margin: 0;
		margin-bottom: 1em;

		@include media($large) {
			margin-bottom: 0;
		}
	}

	&__score {
		display: flex;
		flex-direction: column;

		@include media-max($nexus) {
			flex-direction: row;
			padding-bottom: 0.25em;
			border-bottom: 1px solid $light-grey;
			margin-bottom: 0.25em;

			.scoreguide__value {
				margin-right: 1.5em;
			}
		}
	}

	&__title {
		order: 2;
		margin: 0;
		font-weight: 400;
	}

	&__value {
	}
}


/*  ==========================================================================
	## Guide
	======================================================================= */

.guide {
	padding-top: $pad-small;
	padding-bottom: $pad-large;
	border-bottom: 2px solid;

	&__gradient {
		display: flex;
		justify-content: space-between;
		margin-left: $pad-large * 2;
		margin-bottom: $pad-small / 2;
		border-radius: 5px;
		background: linear-gradient(to left, $red 0%, $orange 25%, $yellow 50%, $greenLight 75%, $green 100%);

		&-point {
			@extend %delta;
			color: $white;
			padding: 0.75em 1em;
			line-height: 1;
		}

		@include media-max($medium) {
			display: none;
		}
	}

	&__wrapper {

		@include media-span($small, $medium) {
			display: flex;
			flex-wrap: wrap;

			.guide__row {
				width: 50%;
			}
		}
	}

	&__row {
		@extend %delta;
		position: relative;
		display: flex;
		justify-content: space-between;
		margin: 1em 0;
		min-height: 2.22em;

		.pillar {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 20;
		}

		@include media-max($small) {
			margin: 1.5em 0;
		}

		@include media-max($medium) {
			flex-direction: column;

			.pillar {
				position: relative;
			}
		}

		@include media($medium) {
			padding-left: $pad-large * 2;
			align-items: center;

			&:before {
				content: '';
				position: absolute;
				z-index: 10;
				border-bottom: 1px solid $light-grey;
				left: 0;
				right: 0;
				top: 50%;
			}
		}
	}

	&__text {
		position: relative;
		z-index: 20;

		@include media-max($medium) {
			margin: 0.5em 0;

			&:before {
				color: $white;
				border-radius: 50%;
				line-height: 1;
				text-align: center;
				font-weight: 400;
				display: inline-block;
				width: 2.22em;
				height: 2.22em;
				vertical-align: middle;
				padding: 0.71em 0;
				margin-right: 0.5em;
			}

			&--high {
				&:before {
					content: '6.0';
					background-color: $green;
				}
			}

			&--mid {
				&:before {
					content: '3.0';
					background-color: $yellow;
				}
			}

			&--low {
				&:before {
					content: '0.0';
					background-color: $red;
				}
			}
		}

		@include media($medium) {
			min-width: 10em;
			background-color: $white;
			padding-left: 1em;
			padding-right: 1em;

			&--high {
				text-align: left;
			}

			&--mid {
				text-align: center;
			}

			&--low {
				text-align: right;
				margin-right: 1em;
			}
		}

	}
}


/*  ==========================================================================
	## Glossary
	======================================================================= */

.glossary {
	margin: 0;

	&__row {
		@extend %delta;
		display: flex;
		padding-top: 0.75em;
		border-top: 1px solid $light-grey;
		margin-bottom: 1.5em;
	}

	&__title {
		font-weight: 500;
		margin: 0;
		flex: 1 1 22%;
	}

	&__value {
		flex: 1 1 78%;
		padding-left: 1em;
	}

	@include media-max($small) {
		&__row {
			flex-direction: column;
		}

		&__value {
			padding-left: 0;
		}
	}
}



/*  ==========================================================================
	## Metrics
	======================================================================= */

.metrics {
	@extend %delta;
	display: flex;
	margin-top: $pad-large;
	margin-left: -$pad-large / 2;
	margin-right: -$pad-large / 2;

	&__col {
		padding-left: $pad-large / 2;
		padding-right: $pad-large / 2;
		padding-top: 0.5rem;
		margin-bottom: 1.5em;
		position: relative;

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: $pad-large / 2;
			right: $pad-large / 2;
			border-top: 2px solid $primary;
		}

	}

	&__heading {
		@extend %delta;
		font-weight: 400;
		margin: 0;
		margin-bottom: 1.5em;
	}

	&__row {
		display: flex;
		margin-bottom: 0.25em;
	}

	&__title {
		order: 2;
		margin: 0;
		font-weight: 400;
		margin-left: 1em;
	}

	&__extra {
		order: 3;
		flex:1 1 auto;
		text-align:right;
	}
}

/*  ==========================================================================
	## Summary List
	======================================================================= */

.summary-list {
	@extend %delta;
	display: flex;

	&__value {
		margin: 0;
		font-weight: 500;
		min-width: 30px;
		text-align:right;
	}

	&__label {
		margin-left: 1.5em;
	}

	&__title {
		@extend %delta;
		font-weight: 400;
		margin: 0;
	}

	&--titled {
		@extend %base;
		margin-top: 0.25rem;
		margin-bottom: 1.5rem;
	}
}


/*  ==========================================================================
	## Rank table
	======================================================================= */

.rank-table {
	@extend %delta;
	margin-bottom: $pad-small;

	thead th {
		font-weight: 400;
		padding-bottom: 1em;
	}

	tbody {

		th,
		td {
			padding-top: 0.75em;
			padding-bottom: 0.75em;
			border-bottom: 0;
			border-top: 1px solid $light-grey;
		}
	}


	&__rank {
		font-weight: 500;
		padding-right: 1em;
	}

	&__value {
		text-align: center;
	}

	&__text {
		padding-right: 1em;

		&--center {
			text-align: center;
		}

		a {
			display: inline-block;
			position: relative;

			&:after {
				content: '';
				display: inline-block;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				border-bottom: 1px solid transparent;
				transition: border 200ms;
			}
			&:hover {
				&:after {
					border-bottom-color: $primary;
				}
			}
		}

		&:last-child {
			padding-left: 1em;
			padding-right: 0;
		}
	}

	// @include media-max($medium) {
	// 	width: 100%;
	// 	border-collapse: collapse;
	// 	border-spacing: 0;
	// 	display: block;
	// 	position: relative;

	// 	td:empty:before {
	// 		content: '\00a0';
	// 	}

	// 	th,
	// 	td {
	// 		margin: 0;
	// 		vertical-align: top;
	// 	}

	// 	th {
	// 		text-align: left;
	// 	}

	// 	thead {
	// 		display: block;
	// 		float: left;

	// 		tr {
	// 			display: block;
	// 			padding: 0 10px 0 0;

	// 			th::before {
	// 				content: "\00a0";
	// 			}
	// 		}
	// 	}

	// 	tbody {
	// 		display: block;
	// 		width: auto;
	// 		position: relative;
	// 		overflow-x: auto;
	// 		white-space: nowrap;

	// 		tr {
	// 			display: inline-block;
	// 			vertical-align: top;
	// 		}
	// 	}

	// 	th {
	// 		display: block;
	// 		text-align: right;
	// 	}

	// 	td {
	// 		display: block;
	// 		min-height: 1.25em;
	// 		text-align: left;
	// 		}

	// 	tr {
	// 		padding: 0 10px;
	// 	}
	// 	/* sort out borders */

	// 	thead {
	// 		border: 0;
	// 		border-right: 1px solid;
	// 	}
	// }

	@include media-max($medium) {
		&__rank {
			// display: none;
		}
	}

	&.tablesaw-swipe .tablesaw-swipe-cellpersist {
		border-right: 0;

		+ .tablesaw-swipe-cellpersist {
			border-right: 2px solid $light-grey;
		}
	}
}

.tablesaw-all-cols-visible  {

	.tablesaw-advance {
		display: none;
	}

	+ .tablesaw-swipe .tablesaw-swipe-cellpersist {
		border-right: 0 !important;
	}
}


/*  ==========================================================================
	## Data point
	======================================================================= */

	// $colors: $red, $orange, $yellow, $greenLight, $green;
	// $stops: 0, 15, 30, 45, 60;

	// @for $c from 1 through length($colors)-1 {
	//   $s1: nth($stops, $c);
	//   $s2: nth($stops, $c+1);
	//   $c1: nth($colors, $c);
	//   $c2: nth($colors, $c+1);
	//   @for $i from $s1 through $s2 {
	//     .bg-#{$i} {
	//       background-color: mix($c2, $c1, 100*($i - $s1)/($s2 - $s1));
	//     }
	//   }
	// }

	$bg-00: #d2383e;
	$bg-05: #e23d47;
	$bg-10: #f24950;
	$bg-15: #fb5e58;
	$bg-20: #fd825e;
	$bg-25: #fda965;
	$bg-30: #fec66c;
	$bg-35: #dcc772;
	$bg-40: #a9c779;
	$bg-45: #79c77f;
	$bg-50: #5bc27e;
	$bg-55: #49b276;
	$bg-60: #3fa16d;

	@for $i from 0 through 4 {
	  .bg-#{$i} {
	    background-color: $bg-00;
	  }
	}

	@for $i from 5 through 9 {
	  .bg-#{$i} {
	    background-color: $bg-05;
	  }
	}

	@for $i from 10 through 14 {
	  .bg-#{$i} {
	    background-color: $bg-10;
	  }
	}

	@for $i from 15 through 19 {
	  .bg-#{$i} {
	    background-color: $bg-15;
	  }
	}

	@for $i from 20 through 24 {
	  .bg-#{$i} {
	    background-color: $bg-20;
	  }
	}

	@for $i from 25 through 29 {
	  .bg-#{$i} {
	    background-color: $bg-25;
	  }
	}

	@for $i from 30 through 34 {
	  .bg-#{$i} {
	    background-color: $bg-30;
	  }
	}

	@for $i from 35 through 39 {
	  .bg-#{$i} {
	    background-color: $bg-35;
	  }
	}

	@for $i from 40 through 44 {
	  .bg-#{$i} {
	    background-color: $bg-40;
	  }
	}

	@for $i from 45 through 49 {
	  .bg-#{$i} {
	    background-color: $bg-45;
	  }
	}

	@for $i from 50 through 54 {
	  .bg-#{$i} {
	    background-color: $bg-50;
	  }
	}

	@for $i from 55 through 59 {
	  .bg-#{$i} {
	    background-color: $bg-55;
	  }
	}

	.bg-60 {
		background-color: $bg-60;
	}

	.data-point {
		@extend %delta;
		display: inline-block;
		width: 2.22em;
		height: 2.22em;
		border-radius: 50%;
		text-align: center;
		vertical-align: middle;
		line-height: 1;
		padding: 0.61em 0;
		margin: 0 0.4em;
	}


/*  ==========================================================================
	## Outlook
	======================================================================= */

.outlook {

	&__item {
		display: flex;
		margin-bottom: $pad-small;
		align-items:center;
	}

	&__pillar {
		flex-shrink: 0;
	}

	&__content {
		@extend %delta;
		padding-left: 1.5em;
		p {
			margin:0;
		}
	}
}


/*  ==========================================================================
	## Radar
	======================================================================= */

.radar {

	&__legend {
		@extend %base;

		+ .scoreguide {
			margin-top: $pad-large;
		}

		ul {
			display: flex;
			justify-content: space-around;

			@include media-max($nexus) {
				flex-direction: column;

				li {
					margin-bottom: 1em;
				}
			}
		}

		li {
			cursor: pointer;
			display: flex;
			line-height: 1.5;
		}

		&--hidden {
			color: rgba($dark-grey, 0.5);

			.radar__swatch {

				&:before {
					opacity: 0.5;
				}

				&:after {
					right: 1em;
					opacity: 0.5;
				}
			}
		}

		#scores + & {
			margin-top: $pad-large;
		}

	}

	&__swatch {
		display: inline-block;
		margin-right: 1em;
		margin-top: 0.25em;
		position: relative;
		height: 0;
		box-sizing: content-box;

		&:before {
			content: '';
			display: inline-block;
			position: relative;
			left: 0;
			width: 2em;
			height: calc(1em + 2px);
			border-radius: 1em;
			border: 1px solid;
		}

		&:after {
			content: '';
			display: inline-block;
			position: absolute;
			z-index: 10;
			width: 1em;
			height: 1em;
			right: 2px;
			top: 2px;
			border-radius: 1px solid transparent;
			border-radius: 50%;
			background-color: inherit;
			vertical-align: middle;
			transition: right 200ms, opacity 200ms;
		}
	}

	&__container {
		&--fullscreen {
			padding-left:3rem;
			padding-right:3rem;
			@include media-max($medium) {
				padding:0;
			}
		}
	}
}


.scatter {

	&__legend {
		@extend .radar__legend;
		margin-top: $pad-small;
	}

	&__legend--hidden {
		@extend .radar__legend--hidden;
	}

	&__swatch {
		@extend .radar__swatch;
	}
}

/*  ==========================================================================
	#Text Elements
	======================================================================= */

/*  ==========================================================================
	##Heading
	======================================================================= */

.heading {
	@extend %beta;
	border-top: 2px solid;
	padding-top: 0.5em;
	display: flex;
	justify-content: space-between;
	margin-bottom: $pad-large;

	.icon {
		font-size: 1.25em;

		&.binoculars {
			font-size: 1.75em;
		}

		&.graph {
			font-size: 1.5em;
		}
	}

	&--button {

		.btn {
			@extend %base;
		}
	}

	&--pillar {
		align-items: center;

		.heading__text {
			margin-left: 0.5em;
			margin-right: auto;
		}
	}
}



/*  ==========================================================================
	##Compare
	======================================================================= */

.compare {

	&__status {
		@extend %gamma;
		font-style: italic;
		margin-bottom: $pad-small;
	}

	&__select {
		margin-bottom: $pad-small;
	}
}


/*  ==========================================================================
	##Summary
	======================================================================= */

.summary {
	justify-content: space-between;
	margin-bottom: $pad-large;

	&:before {
		content: '';
		@extend %beta;
		display: block;
		position: relative;
		width: calc(100% - 1.8rem);
		left: 0.9rem;
		padding-top: 1em;
		border-top: 2px solid $primary;
	}

	&__status {
		background-color: $primary;
		width: 7.5rem;
		height: 7.5rem;
		margin-left: 0.9rem;
		border-radius: 50%;
		flex-basis: auto;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		@include media-max($medium) {
			width: 10rem;
			height: 10rem;
			margin-bottom: $pad-small;
		}
	}

	&__rank {
		color: $white;
		font-size: 55px;
		font-weight: 300;
		line-height: 1;
		margin-bottom: 0.1em;
	}

	&__recommendation {
		@extend %delta;
		color: $white;
	}

	&__description {
		@extend %delta;
	}

	&__links {
		@extend %delta;
		margin-top: $pad-small;

		@include media($ipad) {
			margin-top: 0;
		}

		.summary__link {
			padding-bottom: 0.25em;
			border-bottom: 1px solid $light-grey;
			margin-bottom: 0.75em;

			&:nth-of-type(2) {
				a:hover,
				a:focus {
					color: $greenLight;
				}
			}

			&:nth-of-type(3) {
				a:hover,
				a:focus {
					color: $orange;
				}
			}

			a {
				display: flex;
				width: 100%;
				align-items: center;

				&:hover,
				&:focus {
					color: $green;
				}
			}

			span {
				margin-right: 0.5em;
			}

			.icon {
				margin-left: auto;
			}
		}
	}
}




/*  ==========================================================================
	#Mobile Menu
	======================================================================= */

.mobile-menu {
	background-color: $primary;
	display: flex;
	align-items: center;
	margin: -$pad-small;
	margin-bottom: 0;
	padding: $pad-small;

	@include media($medium) {
		display: none;
	}

	&__logo {
		height: 50px;
		margin-right: auto;
	}

	&__locale,
	&__account {
		appearance: none;
		background-color: transparent;
		border: 0;
		color: $white;
		font-size: 36px;
		padding: 10px;
		margin: 0 5px;
		line-height: 1;
	}

	.hamburger {
		margin-right: -10px;
	}
}


/*  ==========================================================================
	#Side
	======================================================================= */

.side {
	background-color: $primary;
	width: 350px;
	flex-shrink: 0;
	transition: width 400ms;
	padding: $pad-small;
	position: relative;

	&__slide {
		@extend %base;
		display: none;
		cursor: pointer;
		appearance: none;
		border: 0;
		color: $white;
		position: absolute;
		background-color: transparent;
		right: -12px;
		width: 13px;
		height: 75px;
		padding: 0;
		top: 50vh;
		transform: translateY(-50%);

		&:before,
		&:after {
			content: '';
			display: block;
			position: absolute;
			height: 20px;
			top: 26px;
			border-left: 1px solid rgba($white, 0.25);
		}

		&:before {
			left: 3px;
		}

		&:after {
			right: 5px;
		}

		&:focus {
			outline: 0;
		}
	}

	&__logo {
		margin-bottom: $pad-large + $pad-small;
	}

	&__nav {
		@extend %gamma;

		li {
			margin: 1.5em 0;
		}

		a {
			display: block;
			color: $white;
			position: relative;
			overflow: hidden;

			&:hover,
			&.router-link-active {
				.side__nav-arrow {
					transform: translateX(0);
				}

				.side__nav-text {
					border-bottom-color: $white;
				}
			}

			.icon {
				width: 0;
				height: 0;
				transition: width 300ms;
			}
		}

		&-text {
			width: auto;
			display: inline-block;
			overflow: hidden;
			border-bottom: 1px solid transparent;
			transition: border 200ms;
		}

		&-arrow {
			display: inline-block;
			position: absolute;
			right: 0;
			transform: translateX(100%);
			transition: transform 200ms;
		}
	}

	&__panel {
		margin-top: $pad-large;
		border-top: 1px solid $white;
		margin-right: -$pad-small;
		padding-right: $pad-small;
	}

	&__heading {
		@extend %delta;
		font-weight: 400;
		color: $white;
		margin: 1.5rem 0;
	}

	@include media-max($xlarge) {
		width: 100px;
		position: absolute;
		z-index: 400;
		left: 0;
		top: 0;
		bottom: 0;
		padding: $pad-small ($pad-small / 2);

		+ .main {
			margin-left: 100px;
			width: calc(100% - 100px);
		}

		&--expanded {
			width: 300px !important;
			padding: $pad-small;
		}

		&__slide {
			display: block;
		}

		&:not(&--expanded) {

			.side__nav {

				li {
					text-align: center;
				}

				.icon {
					width: 1.5em;
					height: 1.5em;
					transition: width 300ms;
				}

				span {
					width: 0;
					height: 0;
					overflow: hidden;
				}
			}

			.side__panel {
				display: none;
			}
		}
	}


	@include media-max($ipad) {
		width: 70px;

		+ .main {
			margin-left: 70px;
			width: calc(100% - 70px);
		}
	}

	@include media-max($medium) {
		transform: translateX(300px);
		left: auto;
		right: 0;
		padding-top: 0;
		margin-top: calc(5rem + 62px);
		width: 300px;
		transition: transform 300ms;

		&__logo {
			display: none;
		}

		&--expanded {
			transform: translate(0);
		}

		+ .main {
			margin-left: 0;
			width: 100%;
		}
	}
}



/*  ==========================================================================
	#Main
	======================================================================= */

.main {
	background-color: $white;
	width: calc(100% - 350px);
	padding: $pad-small $pad-small $pad-large;

	@include media($xlarge) {
		padding-left: $pad-large;
	}

	.title {

		&__heading {
			@extend %alpha;
			margin: 0;
			line-height: 1.2;
		}

		&__timestamp {
			@extend %alpha;
			color: $mid-grey;
			line-height: 1.2;
		}
	}

	&__content {
		margin-top: $pad-large;

		&.row {
			margin-left: $pad-large / -2;
			margin-right: $pad-large / -2;

			& > * {
				padding-left: $pad-large / 2;
				padding-right: $pad-large / 2;
			}
		}

		&--dashboard {
			margin-top: $pad-large * 2;
		}
	}
}

/*  ==========================================================================
	##Topbar
	======================================================================= */

.topbar {
	align-content: flex-start;
	flex-wrap: wrap;

	@include media($medium) {
		display: flex;
	}

	&__title {
		width: 100%;
		margin-top: $pad-small;

		@include media($ipad) {
			margin-top: 0;
		}
	}

	&__locale,
	&__user {
		display: none;

		@include media($medium) {
			display: block;
		}

		.dropdown--nav .v-select .open-indicator {
			background-color: transparent;

			&:before {
				border-color: $primary $primary transparent transparent;
			}
		}

		.dropdown--nav .v-select .selected-tag {
			color: $primary;
		}
	}

	&__locale {
		margin-left: 0;
		padding-left: 0;
	}

	&__user {
		// margin-left: $pad-large;
	}

	@include media($large) {

		&__title {
			margin-right: auto;
			width: auto;
		}

		&__locale,
		&__user {
			order: 2;
			// margin-left: $pad-large;
		}
	}
}





/*  ==========================================================================
	##Panel
	======================================================================= */

.panel {

	&__content {
		margin-bottom: $pad-large;
	}

	&__filters {

		&:before,
		&:after {
			content: '';
			@extend %beta;
			position: relative;
			width: calc(100% - 1.8rem);
			left: 0.9rem;
		}

		&:before {
			margin-top: $pad-small;
			border-top: 2px solid;
			padding-top: 0.5em;
		}

		&:after {
			margin-top: $pad-large;
			border-bottom: 2px solid;
			// margin-bottom: 0.5em;
		}

		.btn {

			&__wrapper {
				align-self: flex-end;
				text-align: right;
			}

		}


	}

	&--radar {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		margin-bottom: $pad-large;


		.radar__legend {
			margin-bottom: auto;
		}
	}
}

/*  ==========================================================================
	##Rich text
	======================================================================= */

.panel__rich {

	> *:first-child {
		margin-top: 0;
	}

	h3 {
		@extend %gamma;
		font-weight: 500;
		margin: 2em 0 0.5em;
		line-height: 1.2;
	}

	p {
		@extend %delta;
		line-height: 1.6;
		margin-bottom: 1em;
	}

	a {
		text-decoration: underline;
	}
}


/*  ==========================================================================
	##Accordion
	======================================================================= */

.accordion {

	.accordion__heading {
		cursor: pointer;
		transition: opacity 300ms;

		&:hover {
			opacity: 0.9;
		}
	}

	.accordion__icon {
		transform: rotateX(0deg);
		transition: transform 1s;
	}

	.accordion__content {
		@extend .panel__rich;
	}

	&[data-open="true"] {

		.accordion__icon {
			transform: rotateX(180deg);
		}

	}
}



/*  ==========================================================================
	#Footer
	======================================================================= */

.footer {

	.row {
		background-color: $light-grey;
		padding-bottom: $pad-small;
	}

	&__logo,
	&__disclaimer,
	&__links {
		padding: $pad-small;
		text-align: center;

		@include media($medium) {
			text-align: left;
		}
	}

	&__logo {
		width: 100%;
		flex-shrink: 0;

		@include media($ipad) {
			width: 200px;
		}

		@include media($xlarge) {
			width: 350px;
		}
	}

	&__disclaimer {
		padding-left: $pad-small;
		flex-grow: 0;
		flex-basis: 100%;

		h2 {
			@extend %delta;
			font-weight: 400;
			margin: 0 0 1em;
		}

		p {
			@extend %base;
			line-height: 1.8;
		}

		a {
			text-decoration: underline;
		}

		@include media($medium) {
			flex-basis: 60%;
		}

		@include media-max($medium) {
			order: 3;
		}

		@include media($ipad) {
			flex-basis: calc((100% - 200px) *.60);
		}

		@include media($xlarge) {
			padding-left: $pad-large;
			flex-basis: calc((100% - 350px) *.66);
		}
	}

	&__links {
		flex-grow: 0;
		flex-basis: 100%;

		ul {

			li {
				@extend %delta;
			}
		}


		@include media($medium) {
			flex-basis: 40%;
		}

		@include media($ipad) {
			flex-basis: calc((100% - 200px) *.40);
		}

		@include media($large) {
			ul {
				display: flex;
				justify-content: space-between;
			}
		}

		@include media($xlarge) {
			flex-basis: calc((100% - 350px) *.33);
		}

	}
}

/*  ==========================================================================
	### Accordion
	======================================================================= */
[data-control] {
	cursor: pointer;
}

[data-accordion] [data-content] {
	overflow: hidden;
	max-height: 0;
}

.internal__accordion {
	margin: 1rem 0;

	.internal__article + & {
		margin-top: $pad-small;
	}

	[data-control] {
		@extend %base;
		position: relative;
		color: $dark-grey;
		padding-top: 0.25em;
		padding-right: 1.5em;
		border-top: 1px solid;
		line-height: 1.2em;
		margin: 0;
		transition: color 300ms;

		.icon {
			position: absolute;
			right: 0;
			top: 0.25em;
		}

		&:hover {
			color: $black;
		}
	}

	&.open [data-control] {
		color: $black;
		font-weight: 700;
	}

	[data-content] {
		margin-top: 1rem;
		padding-right: 1.5rem;
	}
}





/*  ==========================================================================
	###Pagination
	======================================================================= */

.pagination {
	@extend %base;
	text-align: center;
	display: none;
	width: 100%;
	margin-top: $pad-large;

	@include media($medium) {
		display: block;
	}

	ul {
		list-style-type: none;
		margin-left: 0;
		width: 100%;
	}

	.label {
		display: inline-block;
		width: auto;
		text-align: center;
		line-height: 1;

		@include media($medium) {
			max-width: 11.11111%;
		}

		&.prev a,
		&.next a {
			border: 1px solid;
			border-radius: 50%;

			&:hover,
			&:focus {
				color: $primary;
			}
		}

	}

	a,
	.active span {
		@extend %base;
		color: $black;
		display: block;
		padding: 1em 1.1em;
		line-height: 1;
		border-radius: 50%;
		transition: border 0.3s 0s;
	}

	.active {

		span {
			background-color: $light-grey;
			color: $black;
		}
	}

	a:hover,
	a:active,
	a:focus {
		text-decoration: none;
		color: $primary;
	}

	.link:hover {
		border-bottom-color: $primary;
	}

	.active {
		border-bottom-color: $primary;
	}
}

.mobile-pagination {
	@extend .small-only;
	margin-top: 2.5rem;
	position: relative;
	width: 100%;

	h3 {
		@extend %base;
		margin: 0;
		margin-bottom: 0.75rem;
		padding-top: 0.25rem;
		border-top: 1px solid $dark-grey;
		color: $primary;
	}

	select {
		width: 100%;
		color: $black;
		background-color: transparent;
		border: 2px solid $black;
		border-radius: 10px;
		padding: 0.5em;
		line-height: 1.2;
		transition: all $base-duration;

		&:focus {
			border-color: $primary;
			outline: 0;
			border-radius: 10px 10px 0px 0px;
		}
	}
}


/* ==========================================================================
	#Back to top
	========================================================================== */

#back-top {
	display: none;
	position: fixed;
	bottom: 10px;
	right: 10px;
	text-indent: -999em;
	background-color: rgba(255,255,255,0.1);
	width: 40px;
	height: 40px;
	z-index: 10000;
	transition: background-color 500ms;

	.no-touch &:hover {
		background-color: rgba(255,255,255,0.25);
	}

	&:after {
	  content: '';
	  width: 0px;
	  height: 0px;
	  border-style: solid;
	  border-width: 0 10px 12px 10px;
	  border-color: transparent transparent rgba(255, 255, 255, .6) transparent;
	  position: absolute;
	  top: 13px;
	  left: 10px;
	}
}
