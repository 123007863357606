@font-face {
  font-family: 'Maison Neue';
  src: url('/fonts/MaisonNeue-Light.woff2') format('woff2'),
    url('/fonts/MaisonNeue-Light.woff') format('woff'),
    url('/fonts/MaisonNeue-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Maison Neue';
  src: url('/fonts/MaisonNeue-LightItalic.woff2') format('woff2'),
    url('/fonts/MaisonNeue-LightItalic.woff') format('woff'),
    url('/fonts/MaisonNeue-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Maison Neue';
  src: url('/fonts/MaisonNeue-Book.woff2') format('woff2'),
    url('/fonts/MaisonNeue-Book.woff') format('woff'),
    url('/fonts/MaisonNeue-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Maison Neue';
  src: url('/fonts/MaisonNeue-BookItalic.woff2') format('woff2'),
    url('/fonts/MaisonNeue-BookItalic.woff') format('woff'),
    url('/fonts/MaisonNeue-BookItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Maison Neue';
  src: url('/fonts/MaisonNeue-Demi.woff2') format('woff2'),
    url('/fonts/MaisonNeue-Demi.woff') format('woff'),
    url('/fonts/MaisonNeue-Demi.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Maison Neue';
  src: url('/fonts/MaisonNeue-DemiItalic.woff2') format('woff2'),
    url('/fonts/MaisonNeue-DemiItalic.woff') format('woff'),
    url('/fonts/MaisonNeue-DemiItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

// Typography
$base-font-family: 'runda', sans-serif;
$base-font-family: 'Maison Neue', sans-serif;
$secondary-font-family: $base-font-family, sans-serif;

$heading-font-family: $secondary-font-family;

// Font Sizes
$base-font-size: 10px;
$small-font-size: 12px;
$nexus-font-size: 12px;
$medium-font-size: 14px;
$ipad-font-size: 16px;
$large-font-size: 18px;
$xlarge-font-size: 20px;
$huge-font-size: 20px;
$max-font-size: 20px;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 3px;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Font Colors
$base-font-color: $primary;
$action-color: $primary;

// Animations
$base-duration: 300ms;
$base-timing: ease-in-out;

// Borders
$base-border-color: $black;
$base-border: 1px solid $black;

$small: 480px;
$nexus: 600px;
$medium: 768px;
$ipad: 1024px;

$large: 1280px;
$xlarge: 1440px;
$huge: 1681px;
$max: 1920px;


// Padding
$pad-small: 2.5rem; // 50px
$pad-large: 4rem; // 80px

/**
 * Comment this out in production!
 */
// body:before {
//   content: "base";
//   display: block;
//   position: fixed;
//   z-index: 100000;
//   left: 0;
//   top: 30px;
//   font-size: 13px;
//   font-family: 'Source Code Pro';
//   background-color: rgba($white, 0.9);
//   padding: 0.25em 0.5em;
//   line-height: 1;
//   color: #333;
// }

// @media (min-width: $small) {
//   body:before {
//     content: "small";
//   }
// }

// @media (min-width: $nexus) {
//   body:before {
//     content: "nexus";
//   }
// }

// @media (min-width: $medium) {
//   body:before {
//     content: "medium";
//   }
// }

// @media (min-width: $ipad) {
//   body:before {
//     content: "ipad";
//   }
// }

// @media (min-width: $large) {
//   body:before {
//     content: "large";
//   }
// }

// @media (min-width: $xlarge) {
//   body:before {
//     content: "xlarge";
//   }
// }

// @media (min-width: $huge) {
//   body:before {
//     content: "huge";
//   }
// }

// @media (min-width: $max) {
//   body:before {
//     content: "max";
//   }
// }
