html {

	font-size: $base-font-size;

	@include media($small) {
		font-size: $small-font-size;
	}
	@include media($nexus) {
		font-size: $nexus-font-size;
	}

	@include media($medium) {
		font-size: $medium-font-size;
	}

	@include media($ipad) {
		font-size: $ipad-font-size;
	}

	@include media($large) {
		font-size: $large-font-size;
	}

	@include media($xlarge) {
		font-size: $xlarge-font-size;
	}

	@include media($huge) {
		font-size: $huge-font-size;
	}

	@include media($max) {
		font-size: $max-font-size;
	}
}

body {
	color: $base-font-color;
	font-family: $base-font-family;
	font-weight: 400;
	font-feature-settings: "kern", "liga", "pnum";
	// font-size: $base-font-size;
	line-height: $base-line-height;
	margin: 0;
}


h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $heading-font-family;
	line-height: $heading-line-height;
	margin: 0 0 $small-spacing;
}

p {
	margin: 0 0 $small-spacing;
}

a {
	color: $base-font-color;
	text-decoration: none;
	transition: color $base-duration $base-timing;

	&:active,
	&:focus,
	&:hover {
		color: $action-color;
	}
}

hr {
	border-bottom: $base-border;
	border-left: 0;
	border-right: 0;
	border-top: 0;
	margin: $base-spacing 0;
}

img,
picture {
	margin: 0;
	max-width: 100%;
}

ul,
ol {
	list-style-type: none;
	margin: 0;
	padding: 0;

	&%default-ul {
		list-style-type: disc;
		margin-bottom: $small-spacing;
		padding-left: $base-spacing;
	}

	&%default-ol {
		list-style-type: decimal;
		margin-bottom: $small-spacing;
		padding-left: $base-spacing;
	}
}

dl {
	margin-bottom: $small-spacing;

	dt {
		font-weight: bold;
		margin-top: $small-spacing;
	}

	dd {
		margin: 0;
	}
}

table {
	border-collapse: collapse;
	font-feature-settings: "kern", "liga", "tnum";
	margin: 0;
	width: 100%;
}

th {
	border-bottom: 1px solid shade($base-border-color, 25%);
	font-weight: 600;
	padding: $small-spacing 0;
	text-align: left;
}

td {
	border-bottom: $base-border;
	padding: $small-spacing 0;
}

tr,
td,
th {
	vertical-align: middle;
}

.embed-container,
.video-container
{ position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
	margin: 0 0 $small-spacing;
	font-size: 16px;

	iframe, object, embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


/**
 * Visually hide an element, but leave it available for screen readers
 * @link https://github.com/h5bp/html5-boilerplate/blob/master/dist/css/main.css
 * @link http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.screen-reader {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

/**
 * Extends the .screen-reader class to allow the element to be focusable when navigated to via the keyboard
 * @link https://github.com/h5bp/html5-boilerplate/blob/master/dist/css/main.css
 * @link https://www.drupal.org/node/897638
 */
.screen-reader-focusable:active,
.screen-reader-focusable:focus {
	clip: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	position: static;
	width: auto;
}