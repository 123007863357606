// contain floats
	@mixin clearfix() {
		*zoom: 1;

		&:before,
		&:after {
			display: table;
			content: '';
		}

		&:after {
			clear: both;
		}
	}

// visually hidden
	@mixin visuallyhidden() {
		border: 0;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
	}

// Stripping out units

	@function strip-unit($number) {
		@if type-of($number) == 'number' and not unitless($number) {
			@return $number / ($number * 0 + 1);
		}

		@return $number;
	}

// rem font sizing

	@mixin font-size($size, $mediaMin: null, $mediaMax: null, $query: true) {
		$rem-size: $base-font-size;

		@if $mediaMin == $small {
			$rem-size: $small-font-size;

		} @elseif $mediaMin == $nexus {
			$rem-size: $nexus-font-size;

		} @elseif $mediaMin == $medium {
			$rem-size: $medium-font-size;

		} @elseif $mediaMin == $ipad {
			$rem-size: $ipad-font-size;

		} @elseif $mediaMin == $large {
			$rem-size: $large-font-size;

		} @elseif $mediaMin == $xlarge {
			$rem-size: $xlarge-font-size;

		} @elseif $mediaMin == $huge {
			$rem-size: $huge-font-size;

		} @elseif $mediaMin == $max {
			$rem-size: $max-font-size;
		}

		@if $mediaMin != null and $query {

			@if $mediaMax != null {

				@include media-span($mediaMin, $mediaMax) {
					$remSize: $size / $rem-size;
					font-size: $size;
					font-size: $remSize * 1rem;
				}

			} @else {

				@include media($mediaMin) {
					$remSize: $size / $rem-size;
					font-size: $size;
					font-size: $remSize * 1rem;
				}
			}
		}

		@else {

			$remSize: $size / $rem-size;
			font-size: $size;
			font-size: $remSize * 1rem;

		}

	}


// breakpoints
	@mixin media($point) {
		@media screen and (min-width: $point) {@content;}
	}

	@mixin media-max($point) {
		@media screen and (max-width: $point - 1px) {@content;}
	}

	@mixin media-span($from, $to) {
		@media screen and (min-width: $from) and (max-width: $to - 1px) {@content;}
	}

/* --------------------------------------------------
    #FLUID TYPE
    Responsive Type
    More Infos: http://madebymike.com.au/writing/fluid-type-calc-examples/

    Single property
    html {
      @include fluid-type(font-size, 320px, 1366px, 14px, 18px);
    }

    Multiple properties with same values
    h1 {
      @include fluid-type(padding-bottom padding-top, 20em, 70em, 2em, 4em);
    }
   -------------------------------------------------- */

@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
  @each $property in $properties {
    #{$property}: $min-value;
  }

  @media screen and (min-width: $min-vw) {
    @each $property in $properties {
      #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
    }
  }

  @media screen and (min-width: $max-vw) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

// Shorthand Version
@mixin ft($args...) {
  @include fluid-type($args...);
}

