// Colors
$charade: #2e3241;
$persianRed: #c83232;
$carnation: #fa5050;
$goldenTainoi: #ffc864;
$emerald: #64c87d;
$seaGreen: #329664;
$iron: #d5d6d9;
$athens: #ebebed;

$plotOne: #fda965;

$red: $persianRed;
$orange: $carnation;
$yellow: $goldenTainoi;
$greenLight: $emerald;
$green: $seaGreen;

$primary: $charade;

$error: $red;

$white: #fff;
$black: #000;

$light-gray: $athens;
$light-grey: $light-gray;

$mid-gray: $iron;
$mid-grey: $mid-gray;

$dark-gray: $charade;
$dark-grey: $dark-gray;